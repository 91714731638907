import { ApplicationModel } from ".";
import { GraphApiUser } from "../codegen/graphql";

export class GraphApiUserModel extends ApplicationModel {
  private _data!: GraphApiUser;

  id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  payrollId?: string;
  adUnitCode?: string;
  mobilePhone?: string;
  photo?: string;
  o365Id?: string;

  constructor(data: GraphApiUser) {
    super();
    this._data = data;

    this.id = this._data.id;
    this.email = this._data.email as string;
    this.firstName = this._data.firstName as string;
    this.lastName = this._data.lastName as string;
    this.name = this._data.name as string;
    this.payrollId = this._data.payrollId as string;
    this.adUnitCode = this._data.adUnitCode as string;
    this.mobilePhone = this._data.mobilePhone as string;
    this.photo = this._data.photo as string;
    this.o365Id = this._data.o365Id as string;
  }
}
