import { createInstance } from "@datapunt/matomo-tracker-react";
import { MatomoInstance } from "@datapunt/matomo-tracker-react/lib/types";
import { createContext, useContext } from "react";
import { matomoConfig } from "~/config/matomo";

export default class MatomoClient {
  matomoInstance: MatomoInstance;

  constructor() {
    this.matomoInstance = createInstance(matomoConfig);
  }

  setCostCenter(costCenter: string) {
    this.matomoInstance.pushInstruction("setCustomDimension", 1, costCenter);
  }

  setRoles(roles: string[]) {
    this.matomoInstance.pushInstruction(
      "setCustomDimension",
      2,
      JSON.stringify(roles)
    );
  }
}

export const MatomoClientContext = createContext<MatomoClient>(new MatomoClient());
export const useMatomoClient = () => {
  return useContext(MatomoClientContext);
};
