import {
  ApplicationModel,
  ServiceAreaModel,
  ServiceProductModel,
  ServiceTypeModel,
  GraphApiUserModel,
  ProjectStageModel,
  ContentBlockModel,
  KnowledgeAreaModel,
  ContentCategoryModel,
  ContentAreaModel,
  ContentAreaModelInterface,
} from ".";
import { HasTranslations, applyMixins } from "./mixins";
import {
  DocumentationLibraryPolicy,
  I18nLocalesEnum,
  DocumentationLibrary,
  DocumentationLibraryTranslation as IDocumentationLibraryTranslation,
} from "~/graphql";
import { getDataSortedByPosition, safeEncodeURIComponent } from "~/helpers";

export type ILibraryService =
  | ServiceAreaModel
  | ServiceTypeModel
  | ServiceProductModel
  | null;

export class DocumentationLibraryModel extends ApplicationModel {
  private _data!: DocumentationLibrary;

  id: string;
  name: string;
  slug: string;
  discarded: boolean;
  contentBlocksCount: number;
  headingContentBlock?: ContentBlockModel;
  latestUpdatedContentBlocks: ContentBlockModel[] = [];
  parentLibrary?: DocumentationLibraryModel;
  childrenLibraries: DocumentationLibraryModel[] = [];
  libraryService?: ILibraryService;
  serviceArea?: ServiceAreaModel;
  serviceType?: ServiceTypeModel;
  serviceProduct?: ServiceProductModel;
  contentAreas: ContentAreaModelInterface[];
  knowledgeAreas: KnowledgeAreaModel[];
  projectStages: ProjectStageModel[];
  contentCategories: ContentCategoryModel[];
  contactPerson?: GraphApiUserModel;
  policies: DocumentationLibraryPolicy;
  areasHeading?: string;
  blockAttributes: string[];
  searchAttributes: string[];
  sharepointConfig: {
    tenant: string;
    site: string;
    siteId: string;
    library: string;
    libraryId: string;
    secondaryDirectory: string;
  };
  createdAt: Date;
  updatedAt: Date;

  constructor(data: DocumentationLibrary) {
    super();
    this._data = data;

    this.id = this._data.id;
    this.name = this._data.name;
    this.slug = this._data.slug;
    this.discarded = this._data.discarded;
    this.contentBlocksCount = this._data.contentBlocksCount;

    if (this._data.headingContentBlock)
      this.headingContentBlock = new ContentBlockModel(
        this._data.headingContentBlock
      );
    this.latestUpdatedContentBlocks =
      this._data.latestUpdatedContentBlocks?.map(
        (contentBlock) => new ContentBlockModel(contentBlock)
      );

    if (this._data.parentLibrary)
      this.parentLibrary = new DocumentationLibraryModel(
        this._data.parentLibrary
      );
    this.childrenLibraries =
      this._data.childrenLibraries?.map(
        (library) => new DocumentationLibraryModel(library)
      ) || [];

    if (this._data.serviceArea)
      this.serviceArea = new ServiceAreaModel(this._data.serviceArea);
    if (this._data.serviceType)
      this.serviceType = new ServiceTypeModel(this._data.serviceType);
    if (this._data.serviceProduct)
      this.serviceProduct = new ServiceProductModel(this._data.serviceProduct);

    if (this.serviceProduct) this.libraryService = this.serviceProduct;
    else if (this.serviceType) this.libraryService = this.serviceType;
    else if (this.serviceArea) this.libraryService = this.serviceArea;

    this.contentAreas =
      this._data.contentAreas?.map(
        (contentArea) => ContentAreaModel.resolveArea(contentArea)!
      ) || [];
    this.knowledgeAreas =
      this._data.knowledgeAreas?.map(
        (knowledgeArea) => new KnowledgeAreaModel(knowledgeArea)
      ) || [];
    this.projectStages =
      this._data.projectStages?.map(
        (projectStage) => new ProjectStageModel(projectStage)
      ) || [];
    this.contentCategories =
      this._data.contentCategories?.map(
        (contentCategory) => new ContentCategoryModel(contentCategory)
      ) || [];

    if (this._data.contactPerson)
      this.contactPerson = new GraphApiUserModel(this._data.contactPerson);

    this.policies = this._data.policies;

    this.areasHeading = this._data.areasHeading || undefined;
    this.blockAttributes = this._data.blockAttributes || [];
    this.searchAttributes = this._data.searchAttributes || [];
    this.sharepointConfig = this._data.sharepointConfig;

    this.createdAt = new Date(this._data.createdAt);
    this.updatedAt = new Date(this._data.updatedAt);
  }

  static initialize(id = "new"): DocumentationLibraryModel {
    return new DocumentationLibraryModel({
      id,
      name: "",
      slug: "",
      discarded: false,
      headingContentBlock: null,
      latestUpdatedContentBlocks: [],
      contentBlocksCount: 0,
      serviceArea: null,
      serviceType: null,
      serviceProduct: null,
      knowledgeAreas: [],
      projectStages: [],
      contentCategories: [],
      contactPerson: null,
      policies: {
        index: true,
        show: true,
        create: true,
        update: true,
        destroy: true,
        searchEnabled: true,
        updateOrder: true,
      },
      areasHeading: "",
      blockAttributes: [],
      searchAttributes: [],
      sharepointConfig: {
        tenant: "",
        site: "",
        siteId: "",
        library: "",
        libraryId: "",
        secondaryDirectory: "",
      },
      createdAt: "",
      updatedAt: "",
    });
  }

  get isServiceLibrary(): boolean {
    return !!this.libraryService;
  }

  getRootPath(): string {
    const slug = this.slug;
    if (this.isServiceLibrary) {
      return `/guides/services/${safeEncodeURIComponent(slug)}`;
    } else {
      return `/guides/${safeEncodeURIComponent(slug)}`;
    }
  }

  getKnowledgeAreaNames({
    locale,
  }: {
    locale: I18nLocalesEnum;
  }): (string | undefined)[] {
    return this.fetchCache({
      key: `knowledgeAreaNames_${locale}`,
      fetch: () =>
        this.knowledgeAreas?.map((item) =>
          item.translate<string>("name", locale)
        ) || [],
    });
  }

  getProjectStagesSortedByPosition(): ProjectStageModel[] {
    return this.fetchCache({
      key: "projectStagesSortedByPosition",
      fetch: () =>
        getDataSortedByPosition<ProjectStageModel>(this.projectStages || []),
    });
  }

  getProjectStagesNames({
    locale,
    refresh,
  }: {
    locale: I18nLocalesEnum;
    refresh?: boolean;
  }): (string | undefined)[] {
    return this.fetchCache({
      key: `projectStageNames_${locale}`,
      refresh,
      fetch: () =>
        this.getProjectStagesSortedByPosition().map((item) =>
          item.translate<string>("name", locale)
        ),
    });
  }
}
export interface DocumentationLibraryModel
  extends HasTranslations<IDocumentationLibraryTranslation> {}
applyMixins(DocumentationLibraryModel, [HasTranslations]);
