import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import Spinner from "./components/Spinner";
import { apiUrl, isProduction } from "./config/env";
import { isFeatureEnabled } from "./hooks/featureFlagsHooks";
import { FeatureFlagEnum } from "~/graphql/codegen/graphql";

import styles from "./AppMain.module.css";

const ContentSearch = lazy(() => import("./ContentSearch/ContentSearch"));
const Worksites = lazy(() => import("./Worksites/Worksites"));
const Worksite = lazy(() => import("./Worksite/Worksite"));
const WorksiteNew = lazy(() => import("./Worksite/WorksiteNew"));
const WorksiteEdit = lazy(() => import("./Worksite/WorksiteEdit"));
const ReferenceGroups = lazy(() => import("./ReferenceGroups/ReferenceGroups"));
const ReferenceGroup = lazy(() => import("./ReferenceGroup/ReferenceGroup"));
const ReferenceGroupNew = lazy(
  () => import("./ReferenceGroup/ReferenceGroupNew")
);
const ReferenceGroupEdit = lazy(
  () => import("./ReferenceGroup/ReferenceGroupEdit")
);
const Offers = lazy(() => import("./Offers/Offers"));
const Projects = lazy(() => import("./Projects/Projects"));
const Project = lazy(() => import("./Project/Project"));
const Workspaces = lazy(() => import("./Workspaces/Workspaces"));
const Workspace = lazy(() => import("./Workspace/Workspace"));
const WorkspaceReclamations = lazy(
  () => import("./WorkspaceReclamations/WorkspaceReclamations")
);
const WorkspaceReclamationsOwn = lazy(
  () => import("./WorkspaceReclamationsOwn/WorkspaceReclamationsOwn")
);
const EditWorkspaceReclamation = lazy(
  () =>
    import(
      "./WorkspaceReclamations/EditWorkspaceReclamations/EditWorkspaceReclamation"
    )
);
const WorkspaceEdit = lazy(() => import("./Workspace/WorkspaceEdit"));
const WorkspaceFeedbackNew = lazy(
  () => import("./WorkspaceFeedbacks/WorkspaceFeedbackNew")
);
const WorkspaceFeedbackEdit = lazy(
  () => import("./WorkspaceFeedbacks/WorkspaceFeedbackEdit")
);
const ServicesLibrary = lazy(() => import("./ServicesLibrary/ServicesLibrary"));
const ServicesLibraries = lazy(
  () => import("./ServicesLibraries/ServicesLibraries")
);
const Dashboard = lazy(() => import("./Dashboard/Dashboard"));
const Feedback = lazy(() => import("./Feedback/Feedback"));
const OfferForm = lazy(() => import("./OfferForm/OfferForm"));
const ProjectFeedback = lazy(() => import("./ProjectFeedback/ProjectFeedback")); //view
const ProjectFeedbackNew = lazy(
  () => import("./ProjectFeedback/ProjectFeedbackNew")
);
const ProjectForm = lazy(() => import("./ProjectForm/ProjectForm"));
const Offer = lazy(() => import("./Offer/Offer"));
const Agreements = lazy(() => import("./Agreements/Agreements"));
const Agreement = lazy(() => import("./Agreement/Agreement"));
const AgreementEdit = lazy(() => import("./Agreement/AgreementEdit"));
const AgreementForm = lazy(() => import("./AgreementForm/AgreementForm"));
const WorkorderEdit = lazy(() => import("./Workorder/WorkorderEdit"));
const Changelog = lazy(() => import("./Changelog/Changelog"));
const ReclamationEdit = lazy(() => import("./Reclamation/ReclamationEdit"));
const ReclamationNew = lazy(() => import("./Reclamation/ReclamationNew"));
const RiskAssessmentEdit = lazy(
  () => import("./RiskAssessment/RiskAssessmentEdit")
);
const RiskAssessmentNew = lazy(
  () => import("./RiskAssessment/RiskAssessmentNew")
);
const ProjectReferenceEdit = lazy(
  () => import("./ProjectReference/ProjectReferenceEdit")
);
const ProjectReferenceNew = lazy(
  () => import("./ProjectReference/ProjectReferenceNew")
);
const ProjectReferences = lazy(
  () => import("./ProjectReferences/ProjectReferences")
);
const ResponsibilityQuestionnaireNew = lazy(
  () => import("./ResponsibilityQuestionnaire/ResponsibilityQuestionnaireNew")
);
const ResponsibilityQuestionnaireEdit = lazy(
  () => import("./ResponsibilityQuestionnaire/ResponsibilityQuestionnaireEdit")
);
const ExternalTool = lazy(() => import("./ExternalTool/ExternalTool"));
const NotFound = lazy(() => import("./NotFound/NotFound"));
const LegacyRedirect = lazy(() => import("./LegacyRedirect/LegacyRedirect"));

export default function AppMain({ policies, admins }) {
  const { t } = useTranslation();
  const { superadmin } = admins;

  const showWorkspaces = isFeatureEnabled(FeatureFlagEnum.Workspaces);
  const useVoimaIds = isFeatureEnabled(FeatureFlagEnum.VoimaInternalIdPaths);
  const redirectToWorkspaces =
    showWorkspaces && isFeatureEnabled(FeatureFlagEnum.RedirectToWorkspaces);

  const guidelinesDefaultSlug = "voima-pro";

  return (
    <main className={cx(styles.contentContainer)}>
      <Suspense
        fallback={<Spinner paddings>{t("General.loadingPage")}</Spinner>}
      >
        <Switch>
          <Route
            path="/"
            exact
            render={() => (
              <Redirect
                to={redirectToWorkspaces ? "/workspaces" : "/projects"}
              />
            )}
          />
          <Route
            path={[`/newsroom`, `/newsroom/*`]}
            exact
            component={() => {
              window.location.replace("https://intra.sitowise.com");
              return null;
            }}
          />

          <Route path="/feedback" exact component={Feedback} />

          {policies?.worksite?.index && (
            <Route
              path="/worksites"
              exact
              render={(routeProps) => (
                <Worksites {...routeProps} policies={policies} />
              )}
            />
          )}
          {policies?.worksite?.create && (
            <Route path="/worksites/new" exact component={WorksiteNew} />
          )}
          {policies?.referenceGroup?.index && (
            <Route path="/reference_groups" exact component={ReferenceGroups} />
          )}
          <Route
            path="/reference_groups/new"
            exact
            component={ReferenceGroupNew}
          />
          <Route
            path="/reference_groups/:referenceGroupId/edit"
            exact
            component={ReferenceGroupEdit}
          />

          {!redirectToWorkspaces && (
            <Route path="/offers" exact component={Offers} />
          )}
          {!redirectToWorkspaces && (
            <Route path="/projects" exact component={Projects} />
          )}
          {redirectToWorkspaces && (
            <Redirect from="/projects" to="/workspaces" exact />
          )}
          {redirectToWorkspaces && (
            <Redirect from="/offers" to="/workspaces" exact />
          )}
          {showWorkspaces && (
            <Route
              path="/reclamations/own"
              exact
              render={() => <WorkspaceReclamationsOwn />}
            />
          )}
          {showWorkspaces && (
            <Route path="/workspaces" exact component={Workspaces} />
          )}
          {policies?.worksite?.index && (
            <Route
              path="/worksites/:worksiteId/edit"
              exact
              component={WorksiteEdit}
            />
          )}
          {showWorkspaces && (
            <Route
              path="/workspaces/:workspaceId/edit"
              exact
              render={(routeProps) => <WorkspaceEdit {...routeProps} />}
            />
          )}
          {policies?.frameAgreement.index && (
            <Route path="/frame_agreements/" exact component={Agreements} />
          )}
          {policies?.frameAgreement.create && (
            <Route
              path="/frame_agreements/new"
              exact
              render={(routeProps) => <AgreementForm isNew />}
            />
          )}
          <Route
            path="/frame_agreements/:agreementId/edit"
            exact
            component={AgreementEdit}
          />
          <Route path="/references" exact component={ProjectReferences} />

          {superadmin && (
            <Route
              path={[
                "/admin",
                "/admin_settings",
                "/admin/*",
                "/admin_settings/*",
              ]}
              exact
              component={(props) => {
                const location = props.location.pathname.replace(
                  /^\/admin_settings/,
                  "/admin"
                );
                window.location.replace(`${apiUrl}${location}`);
                return null;
              }}
            />
          )}
          <Route path="/changelog" exact component={Changelog} />

          <Route
            path="/reclamations/:reclamationId/edit"
            exact
            component={ReclamationEdit}
          />
          <Route
            path="/risk_assessments/:riskAssessmentId/edit"
            exact
            component={RiskAssessmentEdit}
          />

          {useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/projects/by_id/:projectIdentifier/edit"
              exact
              render={(routeProps) => (
                <ProjectForm {...routeProps} policies={policies} />
              )}
            />
          )}
          {useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/projects/by_id/:projectIdentifier/reclamations/new"
              exact
              component={ReclamationNew}
            />
          )}
          {useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/projects/by_id/:projectIdentifier/risk_assessments/new"
              exact
              component={RiskAssessmentNew}
            />
          )}
          {useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/projects/by_id/:projectIdentifier/feedbacks/:surveyType/new"
              exact
              component={ProjectFeedbackNew}
            />
          )}
          {useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/projects/by_id/:projectIdentifier/reference/new"
              exact
              component={ProjectReferenceNew}
            />
          )}
          {useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/projects/by_id/:projectIdentifier/reference/edit"
              exact
              component={ProjectReferenceEdit}
            />
          )}
          {useVoimaIds && !isProduction && !redirectToWorkspaces && (
            <Route
              path="/projects/by_id/:projectIdentifier/workorders/:workorderId"
              exact
              component={WorkorderEdit}
            />
          )}
          {useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/projects/by_id/:identifier/responsibility_questionnaire/edit"
              exact
              component={ResponsibilityQuestionnaireEdit}
            />
          )}
          {useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/projects/by_id/:identifier/responsibility_questionnaire/new"
              exact
              component={ResponsibilityQuestionnaireNew}
            />
          )}
          {useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/projects/by_id/:projectIdentifier"
              component={Project}
            />
          )}
          {useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/offers/by_id/:offerIdentifier/risk_assessments/new"
              exact
              component={RiskAssessmentNew}
            />
          )}
          {useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/offers/by_id/:offerIdentifier/edit"
              exact
              render={(routeProps) => (
                <OfferForm {...routeProps} policies={policies} />
              )}
            />
          )}
          {useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/offers/by_id/:identifier/responsibility_questionnaire/edit"
              exact
              component={ResponsibilityQuestionnaireEdit}
            />
          )}
          {useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/offers/by_id/:identifier/responsibility_questionnaire/new"
              exact
              component={ResponsibilityQuestionnaireNew}
            />
          )}
          {useVoimaIds && !redirectToWorkspaces && (
            <Route path="/offers/by_id/:offerIdentifier" component={Offer} />
          )}

          <Route
            path="/external/:entityType/by_id/:entityIdentifier/:linkType"
            exact
            component={ExternalTool}
          />

          {!useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/projects/:projectIdentifier/edit"
              exact
              render={(routeProps) => (
                <ProjectForm {...routeProps} policies={policies} />
              )}
            />
          )}
          {!useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/projects/:projectIdentifier/reclamations/new"
              exact
              component={ReclamationNew}
            />
          )}
          {!useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/projects/:projectIdentifier/risk_assessments/new"
              exact
              component={RiskAssessmentNew}
            />
          )}
          {!useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/projects/:projectIdentifier/feedbacks/:surveyType/new"
              exact
              component={ProjectFeedbackNew}
            />
          )}
          {!useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/projects/:projectIdentifier/reference/new"
              exact
              component={ProjectReferenceNew}
            />
          )}
          {!useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/projects/:projectIdentifier/reference/edit"
              exact
              component={ProjectReferenceEdit}
            />
          )}
          {!useVoimaIds && !redirectToWorkspaces && !isProduction && (
            <Route
              path="/projects/:projectIdentifier/workorders/:workorderId"
              exact
              component={WorkorderEdit}
            />
          )}
          {!useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/projects/:identifier/responsibility_questionnaire/edit"
              exact
              component={ResponsibilityQuestionnaireEdit}
            />
          )}
          {!useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/projects/:identifier/responsibility_questionnaire/new"
              exact
              component={ResponsibilityQuestionnaireNew}
            />
          )}
          {!useVoimaIds && !redirectToWorkspaces && (
            <Route path="/projects/:projectIdentifier" component={Project} />
          )}
          {!useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/offers/:offerIdentifier/risk_assessments/new"
              exact
              component={RiskAssessmentNew}
            />
          )}
          {!useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/offers/:offerIdentifier/edit"
              exact
              render={(routeProps) => (
                <OfferForm {...routeProps} policies={policies} />
              )}
            />
          )}
          {!useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/offers/:identifier/responsibility_questionnaire/edit"
              exact
              component={ResponsibilityQuestionnaireEdit}
            />
          )}
          {!useVoimaIds && !redirectToWorkspaces && (
            <Route
              path="/offers/:identifier/responsibility_questionnaire/new"
              exact
              component={ResponsibilityQuestionnaireNew}
            />
          )}
          {!useVoimaIds && !redirectToWorkspaces && (
            <Route path="/offers/:offerIdentifier" component={Offer} />
          )}

          <Route
            path="/external/:entityType/:entityIdentifier/:linkType"
            exact
            component={ExternalTool}
          />

          <Redirect
            from="/projects/by_id/:projectIdentifier/risk_assessments/:riskAssessmentId/summary"
            to="/risk_assessments/:riskAssessmentId/edit"
            exact
          />
          <Redirect
            from="/projects/:projectIdentifier/risk_assessments/:riskAssessmentId/summary"
            to="/risk_assessments/:riskAssessmentId/edit"
            exact
          />
          <Redirect
            from="/offers/by_id/:offerIdentifier/risk_assessments/:riskAssessmentId/summary"
            to="/risk_assessments/:riskAssessmentId/edit"
            exact
          />
          <Redirect
            from="/offers/:offerIdentifier/risk_assessments/:riskAssessmentId/summary"
            to="/risk_assessments/:riskAssessmentId/edit"
            exact
          />

          {redirectToWorkspaces && (
            <Route
              path="/projects/by_id/:projectIdentifier"
              render={(routeProps) => (
                <LegacyRedirect
                  {...routeProps}
                  redirectToWorkspaces={redirectToWorkspaces}
                  useVoimaIds={useVoimaIds}
                />
              )}
            />
          )}
          {(useVoimaIds || redirectToWorkspaces) && (
            <Route
              path="/projects/by_identifier/:projectIdentifier"
              render={(routeProps) => (
                <LegacyRedirect
                  {...routeProps}
                  redirectToWorkspaces={redirectToWorkspaces}
                  useVoimaIds={useVoimaIds}
                />
              )}
            />
          )}
          {(useVoimaIds || redirectToWorkspaces) && (
            <Route
              path="/projects/by_unit4_id/:projectIdentifier"
              render={(routeProps) => (
                <LegacyRedirect
                  {...routeProps}
                  redirectToWorkspaces={redirectToWorkspaces}
                  useVoimaIds={useVoimaIds}
                />
              )}
            />
          )}
          {(useVoimaIds || redirectToWorkspaces) && (
            <Route
              path="/projects/:projectIdentifier"
              render={(routeProps) => (
                <LegacyRedirect
                  {...routeProps}
                  redirectToWorkspaces={redirectToWorkspaces}
                  useVoimaIds={useVoimaIds}
                />
              )}
            />
          )}
          {redirectToWorkspaces && (
            <Route
              path="/offers/by_id/:offerIdentifier"
              render={(routeProps) => (
                <LegacyRedirect
                  {...routeProps}
                  redirectToWorkspaces={redirectToWorkspaces}
                  useVoimaIds={useVoimaIds}
                />
              )}
            />
          )}
          {(useVoimaIds || redirectToWorkspaces) && (
            <Route
              path="/offers/by_identifier/:offerIdentifier"
              render={(routeProps) => (
                <LegacyRedirect
                  {...routeProps}
                  redirectToWorkspaces={redirectToWorkspaces}
                  useVoimaIds={useVoimaIds}
                />
              )}
            />
          )}
          {(useVoimaIds || redirectToWorkspaces) && (
            <Route
              path="/offers/by_unit4_id/:offerIdentifier"
              render={(routeProps) => (
                <LegacyRedirect
                  {...routeProps}
                  redirectToWorkspaces={redirectToWorkspaces}
                  useVoimaIds={useVoimaIds}
                />
              )}
            />
          )}
          {(useVoimaIds || redirectToWorkspaces) && (
            <Route
              path="/offers/:offerIdentifier"
              render={(routeProps) => (
                <LegacyRedirect
                  {...routeProps}
                  redirectToWorkspaces={redirectToWorkspaces}
                  useVoimaIds={useVoimaIds}
                />
              )}
            />
          )}

          {policies?.worksite?.index && (
            <Route path="/worksites/:worksiteId" component={Worksite} />
          )}
          {policies?.referenceGroup?.index && (
            <Route
              path="/reference_groups/:referenceGroupId"
              component={ReferenceGroup}
            />
          )}

          {showWorkspaces && (
            <Route
              path="/workspaces/:workspaceId/reclamations/:reclamationId/edit"
              component={EditWorkspaceReclamation}
            />
          )}
          {showWorkspaces && (
            <Route
              path="/workspaces/:workspaceId/reclamations/new"
              component={EditWorkspaceReclamation}
            />
          )}
          {showWorkspaces && (
            <Route
              path="/workspaces/:workspaceId/reclamations"
              component={WorkspaceReclamations}
            />
          )}

          {showWorkspaces && (
            <Route
              path="/workspaces/:workspaceId/workorders/:workorderId"
              exact
              component={WorkorderEdit}
            />
          )}
          {showWorkspaces && (
            <Route
              path="/workspaces/by_identifier/:workspaceIdentifier"
              render={(routeProps) => (
                <LegacyRedirect
                  {...routeProps}
                  redirectToWorkspaces={redirectToWorkspaces}
                  useVoimaIds={useVoimaIds}
                />
              )}
            />
          )}

          {showWorkspaces && (
            <Route
              path="/workspaces/:workspaceId/risk_assessments/new"
              exact
              component={RiskAssessmentNew}
            />
          )}
          {showWorkspaces && (
            <Route
              path="/workspaces/:workspaceId/risk_assessments/:riskAssessmentId/edit"
              exact
              component={RiskAssessmentEdit}
            />
          )}

          {showWorkspaces && (
            <Route
              path="/workspaces/:workspaceIdentifier/feedbacks/:feedbackId"
              exact
              component={ProjectFeedback}
            />
          )}
          {showWorkspaces && (
            <Route
              path="/workspaces/:workspaceIdentifier/feedbacks/:surveyType/new"
              exact
              component={WorkspaceFeedbackNew}
            />
          )}
          {showWorkspaces && (
            <Route
              path="/workspaces/:workspaceIdentifier/feedbacks/:feedbackId/edit"
              exact
              component={WorkspaceFeedbackEdit}
            />
          )}
          {showWorkspaces && (
            <Route path="/workspaces/:workspaceId" component={Workspace} />
          )}
          {policies?.frameAgreement.index && (
            <Route
              path="/frame_agreements/:agreementId"
              component={Agreement}
            />
          )}
          <Route
            path="/feedbacks/:feedbackId"
            exact
            component={ProjectFeedback}
          />
          <Route path="/dashboard" component={Dashboard} />

          <Route path="/guidelines/search" exact component={ContentSearch} />
          <Route
            path={[
              "/guidelines/services/:library_slug/:project_stage_name",
              "/guidelines/services/:library_slug/:project_stage_name/versions",
            ]}
            exact
            component={ServicesLibrary}
          />
          <Route
            path="/guidelines/services"
            exact
            component={ServicesLibraries}
          />
          <Route
            path="/guidelines/services/:library_slug"
            exact
            component={ServicesLibrary}
          />
          <Route
            path={[
              "/guidelines/:library_slug/:project_stage_name/:knowledge_area_name",
              "/guidelines/:library_slug/:project_stage_name/:knowledge_area_name/versions",
            ]}
            exact
            component={ServicesLibrary}
          />
          <Route
            path="/guidelines/:library_slug"
            exact
            component={ServicesLibrary}
          />
          <Redirect
            from="/guidelines"
            to={`/guidelines/${guidelinesDefaultSlug}`}
          />

          <Route path="/guides/search" exact component={ContentSearch} />
          <Route
            path={[
              "/guides/services/:library_slug/:project_stage_slug",
              "/guides/services/:library_slug/:project_stage_slug/versions",
            ]}
            exact
            component={ServicesLibrary}
          />
          <Route
            path={[
              "/guides/services/:library_slug/:project_stage_slug/:knowledge_area_slug",
              "/guides/services/:library_slug/:project_stage_slug/:knowledge_area_slug/versions",
            ]}
            exact
            component={ServicesLibrary}
          />
          <Route path="/guides/services" exact component={ServicesLibraries} />
          <Route
            path="/guides/services/:library_slug"
            exact
            component={ServicesLibrary}
          />
          <Route
            path={[
              "/guides/:library_slug/:project_stage_slug/:knowledge_area_slug",
              "/guides/:library_slug/:project_stage_slug/:knowledge_area_slug/versions",
            ]}
            exact
            component={ServicesLibrary}
          />
          <Route
            path="/guides/:library_slug"
            exact
            component={ServicesLibrary}
          />
          <Redirect from="/guides" to={`/guides/${guidelinesDefaultSlug}`} />

          <Route
            render={(routeProps) => (
              <NotFound {...routeProps} loadingPolicies={!policies}>
                <p>
                  <NavLink to="/">{t("General.backToHomePage")}</NavLink>
                </p>
              </NotFound>
            )}
          />
        </Switch>
      </Suspense>
    </main>
  );
}
