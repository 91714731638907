import * as React from "react";
import { NavLink, matchPath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import UserInfo from "../components/UserInfo";
import LanguageSwitcher from "../components/LanguageSwitcher";
import { projektikarttaUrl, isProduction } from "../config/env";

import styles from "./RootNavigation.module.css";
import { ReactComponent as WorkspaceIcon } from "../images/workspace-icon.svg";
import { ReactComponent as VoimaProIcon } from "../images/voimapro-icon.svg";
import { ReactComponent as AdminIcon } from "../images/admin-icon.svg";
import { ReactComponent as UpdatesIcon } from "../images/updates-icon.svg";
import { ReactComponent as AccountIcon } from "../images/account-circle-outline.svg";

const workspacePaths = [
  "/worksites",
  "/projects",
  "/offers",
  "/frame_agreements",
  "/references",
  "/reference_groups",
  "/reclamations",
  "/risk_assessments",
];

const referencePaths = ["/references", "/reference_groups"];

function NavGroup({
  url,
  label,
  icon,
  children,
  className,
  isActive,
}: {
  url: string;
  label: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  isActive?: (match: any, location: any) => boolean;
}) {
  return (
    <div className={cx(styles.navLinksSpacing)}>
      <NavLink
        className={cx(
          "childMarginsQuarterX",
          styles.link,
          styles.linkMain,
          className
        )}
        activeClassName={styles.activeLink}
        isActive={isActive}
        to={url}
      >
        {icon && <span className={styles.navIcon}>{icon}</span>}
        <span>{label}</span>
      </NavLink>
      <div className={styles.subNavigation}>{children}</div>
    </div>
  );
}

function ReferencesNavGroup({ policies, t }) {
  if (!policies?.referenceGroup?.index) {
    return (
      <NavLink
        className={styles.link}
        activeClassName={styles.activeLink}
        to="/references"
      >
        {t("Navigation.references")}
      </NavLink>
    );
  } else {
    return (
      <NavGroup
        url="/references"
        label={t("Navigation.references")}
        isActive={(_match, location) => {
          const match = matchPath(location.pathname, {
            path: referencePaths,
          });
          return match;
        }}
      >
        <div className={styles.subNavigationChildren}>
          <NavLink
            className={cx(styles.link, styles.subLinks)}
            activeClassName={styles.activeLink}
            to="/references"
          >
            {t("Navigation.referenceSearch")}
          </NavLink>
          <NavLink
            className={cx(styles.link, styles.subLinks)}
            activeClassName={styles.activeLink}
            to="/reference_groups"
          >
            {t("Navigation.referenceGroups")}
          </NavLink>
        </div>
      </NavGroup>
    );
  }
}

export default function RootNavigation({
  policies,
  superadmin,
  documentationLibraryAdmin,
  showWorkspaces,
  userInfo,
  logOut,
  redirectToWorkspaces,
}) {
  const { t } = useTranslation();

  return (
    <div
      className={cx(styles.navigationLinks, styles.rootNavi, "childMarginsY")}
    >
      <div className="flexY childMarginsY">
        {!isProduction && <NavGroup url="/dashboard" label="Dashboard" />}

        <NavGroup
          url={redirectToWorkspaces ? "/workspaces" : "/projects"}
          label="WORKSPACES"
          isActive={(_match, location) => {
            const match = matchPath(location.pathname, {
              path: workspacePaths,
            });

            return match;
          }}
          icon={<WorkspaceIcon />}
        >
          {policies.worksite?.index && (
            <NavLink
              className={styles.link}
              activeClassName={styles.activeLink}
              to="/worksites"
            >
              {t("Navigation.worksites")}
            </NavLink>
          )}
          {showWorkspaces && (
            <NavLink
              className={styles.link}
              activeClassName={styles.activeLink}
              to="/workspaces"
            >
              {t("Navigation.workspaces")}
            </NavLink>
          )}
          {!redirectToWorkspaces && (
            <NavLink
              className={styles.link}
              activeClassName={styles.activeLink}
              to="/projects"
            >
              {t("Navigation.projects")}
            </NavLink>
          )}
          {!redirectToWorkspaces && (
            <NavLink
              className={styles.link}
              activeClassName={styles.activeLink}
              to="/offers"
            >
              {t("Navigation.offers")}
            </NavLink>
          )}
          {policies.frameAgreement?.index && (
            <NavLink
              className={styles.link}
              activeClassName={styles.activeLink}
              to="/frame_agreements"
            >
              {t("Navigation.frameAgreements")}
            </NavLink>
          )}
          <ReferencesNavGroup policies={policies} t={t} />
        </NavGroup>

        <NavGroup url={"/guides"} label="PRO" icon={<VoimaProIcon />}>
          <NavLink
            className={styles.link}
            activeClassName={styles.activeLink}
            to="/guides"
            isActive={(match, location) => {
              if (!match) return false;

              const isLegacyGuidelines =
                location.pathname.match(/^\/guidelines.+/g) &&
                !location.pathname.match(
                  /^\/guidelines\/services.*|^\/guidelines\/search.*/g
                );
              const isGuides =
                location.pathname.match(/^\/guides.+/g) &&
                !location.pathname.match(
                  /^\/guides\/services.*|^\/guides\/search.*/g
                );
              return !!(isLegacyGuidelines || isGuides);
            }}
          >
            {t("Navigation.projectGuidelines")}
          </NavLink>
          {policies?.documentationLibrary?.index && (
            <NavLink
              className={styles.link}
              activeClassName={styles.activeLink}
              to="/guides/services"
            >
              {t("Navigation.serviceInstructions")}
            </NavLink>
          )}
          <NavLink
            className={styles.link}
            activeClassName={styles.activeLink}
            to="/guides/search"
          >
            {t("Navigation.search")}
          </NavLink>
        </NavGroup>

        {(superadmin || documentationLibraryAdmin) && (
          <NavGroup
            url="/admin"
            label={t("Navigation.admin")}
            icon={<AdminIcon />}
          />
        )}
        <NavGroup
          url="/changelog"
          label={t("Navigation.updates")}
          className={styles.updates}
          icon={<UpdatesIcon />}
        />
      </div>
      <div className="flexY childMarginsY">
        <div className={styles.feedbackSlot}>
          <a
            href={projektikarttaUrl}
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("Navigation.projectMap")}
          </a>
          <a
            href="https://teams.microsoft.com/l/channel/19%3a9d194b00000848f59508b8cc1858a69a%40thread.skype/Voima-kysymykset?groupId=9690ea3d-0760-4eed-a04d-986a7b545cd2&tenantId=b67da565-e2fb-4473-b9db-83695070d988"
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("Navigation.sendFeedback")}
          </a>
        </div>

        {<LanguageSwitcher />}

        <div
          className={cx(
            "flex alignStart justify",
            styles.navBottom,
            styles.profile
          )}
        >
          <AccountIcon />
          <div className="flexY alignEnd">
            <UserInfo
              displayName={userInfo && userInfo.displayName}
              o365Id={userInfo && userInfo.id}
              mail={userInfo && userInfo.userPrincipalName}
            />
            <button
              type="button"
              onClick={logOut}
              className="link nowrap small"
            >
              {t("Navigation.logOut")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
