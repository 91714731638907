import actionReporter from "~/config/initializers/actionReporter";

import "core-js/es/symbol";
import "url-search-params-polyfill";

import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import "array-flat-polyfill";
import fromEntries from "fromentries";

import { ApolloProvider } from "@apollo/client";
import { MsalProvider } from "@azure/msal-react";
import * as React from "react";
import ReactDOM from "react-dom/client";
import { QueryClientProvider } from "react-query";

import authApp from "~/config/initializers/authClient";
import clientProxy from "~/config/initializers/clientProxy";
import i18n from "~/config/initializers/i18n";
import matomoClient from "~/config/initializers/matomo";
import queryClient from "~/config/initializers/queryClient";

import { ClientProxyContext } from "~/lib/ClientProxy";
import { MatomoClientContext } from "~/lib/MatomoClient";

import App from "./App";

// Remove the following code
// window.onerror = function (message, source, lineno, colno, error) {
//   handleGlobalError(error || new Error(message as string));
// };

Object.fromEntries = Object.fromEntries || fromEntries;

i18n.on("initialized", () => {
  authApp.initialize().then(() => {
    let container = document.getElementById("root");
    if (!container) {
      throw new Error("Invalid index.html template");
    }

    const app = (
      <actionReporter.errorBoundary worker={actionReporter}>
          <MsalProvider instance={authApp}>
            <ClientProxyContext.Provider value={clientProxy}>
              <ApolloProvider client={clientProxy.apolloClient}>
                <QueryClientProvider client={queryClient}>
                  <MatomoClientContext.Provider value={matomoClient}>
                    <App authApp={authApp} />
                  </MatomoClientContext.Provider>
                </QueryClientProvider>
              </ApolloProvider>
            </ClientProxyContext.Provider>
          </MsalProvider>
      </actionReporter.errorBoundary>
    );

    const root = ReactDOM.createRoot(container);
    root.render(app);
  });
});

// import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: "YOUR_SENTRY_DSN",
//   integrations: [],  // Remove BrowserTracing
//   tracesSampleRate: 1.0,
// });
