import * as React from "react";
import { NavLink, matchPath, useLocation } from "react-router-dom";
import cx from "classnames";

import { infoIcon } from "./utils";
import styles from "./SubNavLink.module.scss";

function generalPaths(navPage, pathname, url) {
  if (navPage || pathname === url || pathname === `${url}/edit`) {
    return url;
  }
}

function checkGeneralPaths(navPage, pathname, url) {
  const match = matchPath(pathname, {
    path: generalPaths(navPage, pathname, url),
  });
  return match;
}

function checkExceptionPaths(navPage, pathname) {
  const match = matchPath(pathname, {
    path: [`/${navPage}/:id`, `/${navPage}/:id/edit`],
    exact: true,
  });
  return match;
}

function checkLocation(location, url, type) {
  const { pathname, search } = location;
  const urlArr = url?.split("/");
  const navPage = urlArr && urlArr[3] ? urlArr[3] : undefined;

  switch (type) {
    case "projects":
      if (!pathname.includes("projects")) {
        if (
          ["feedbacks", "reclamations", "risk_assessments"].includes(navPage)
        ) {
          return checkExceptionPaths(navPage, pathname);
        }
      } else {
        return checkGeneralPaths(navPage, pathname, url);
      }
      break;
    case "offers":
      if (!pathname.includes("offers")) {
        if (["risk_assessments"].includes(navPage)) {
          return checkExceptionPaths(navPage, pathname);
        }
      } else {
        return checkGeneralPaths(navPage, pathname, url);
      }
      break;
    case "frameAgreements":
      return checkGeneralPaths(navPage, `${pathname}${search}`, url);
    default:
      return checkGeneralPaths(navPage, pathname, url);
  }
}

export default function SubNavLink({
  exact,
  label,
  url,
  disabled,
  type,
  showInfoCircle,
}: {
  exact?: boolean;
  label: string;
  url: string;
  disabled?: boolean;
  type: string;
  showInfoCircle?: boolean;
}) {
  const isActive = checkLocation(useLocation(), url, type);

  return (
    <NavLink
      exact={exact}
      activeClassName={styles.activeLink}
      isActive={(_match, location) => checkLocation(location, url, type)}
      className={cx("flex childMarginsHalfX", styles.link)}
      to={url}
      disabled={disabled}
      onClick={(e) => disabled && e.preventDefault()}
    >
      <p>{label}</p>
      {showInfoCircle && (
        <div
          className={isActive ? styles.iconActive : styles.icon}
          dangerouslySetInnerHTML={{
            __html: infoIcon,
          }}
        />
      )}
    </NavLink>
  );
}
