import * as React from "react";
import cx from "classnames";

import styles from "./Spinner.module.css";

const Spinner = ({
  children,
  initialLineHeight,
  paddings,
}: {
  children?: React.ReactNode;
  initialLineHeight?: boolean;
  paddings?: boolean;
}) => {
  return (
    <div
      className={cx(
        styles.container,
        initialLineHeight && styles.containerInitialLineHeight,
        paddings ? styles.paddings : ""
      )}
    >
      <div className={styles.centerer}>
        <div className={styles.spinner} />
        <div>{children}</div>
      </div>
    </div>
  );
};
export default Spinner;
