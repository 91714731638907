import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { PublicClientApplication, publicClientConfig } from "../auth";
import { isTest } from "../env";

if (isTest) {
  const testAccessToken =
    "eyJhbGciOiJIUzI1NiJ9.eyJzY3AiOiJVc2VyLlJlYWQiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9jb250b3NvIiwiYXBwX2Rpc3BsYXluYW1lIjoiYXBwbGljYXRpb24tbmFtZSIsInRpZCI6InRlbmFudC1pZCIsInRlbmFudF9yZWdpb25fc2NvcGUiOiJYWCIsImF1ZCI6IjAwMDAwMDAzLTAwMDAtMDAwMC1jMDAwLTAwMDAwMDAwMDAwMCIsInVuaXF1ZV9uYW1lIjoiam9obi5kb2VAY29udG9zby5jb20iLCJuYmYiOiIxNjU2NTk5NjI2IiwiYXBwaWQiOiJhcHAtaWQiLCJuYW1lIjoiSm9obiBEb2UiLCJleHAiOiIxNjU2NjA0NzY3IiwiaWF0IjoiMTY1NjU5OTYyNiIsImVtYWlsIjoiam9obi5kb2VAY29udG9zby5jb20ifQ.ftOvEUhqEFKWxIcxcYsgstgldO-31hIVwEhQ0hwwqWg";

  const testAccountInfo: AccountInfo = {
    homeAccountId: "home-account-id",
    localAccountId: "local-account-id",
    environment: "login.windows.net",
    tenantId: "tenant-id",
    username: "antti.kallio@sitowise.com",
    name: "Antti Kallio",
  };

  const testAuthenticationResult: AuthenticationResult = {
    authority: "https://login.microsoftonline.com",
    uniqueId: "unique-id",
    tenantId: "tenant-id",
    scopes: ["openid", "profile"],
    idToken: "test-id-token",
    idTokenClaims: {},
    accessToken: testAccessToken,
    fromCache: false,
    correlationId: "test-correlation-id",
    expiresOn: new Date(Date.now() + 3600000),
    account: testAccountInfo,
    tokenType: "Bearer",
  };

  PublicClientApplication.prototype.acquireTokenPopup = function () {
    return Promise.resolve(testAuthenticationResult);
  };
  PublicClientApplication.prototype.acquireTokenRedirect = function () {
    return Promise.resolve();
  };
  PublicClientApplication.prototype.acquireTokenSilent = function () {
    // this.controller.getEventHandler().emitEvent(EventType.ACQUIRE_TOKEN_SUCCESS, InteractionType.Silent, testAuthenticationResult);
    return Promise.resolve(testAuthenticationResult);
  };
  PublicClientApplication.prototype.acquireTokenByCode = function () {
    return Promise.resolve(testAuthenticationResult);
  };
  PublicClientApplication.prototype.getAllAccounts = function () {
    return [testAccountInfo];
  };
  PublicClientApplication.prototype.handleRedirectPromise = function () {
    return Promise.resolve(testAuthenticationResult);
  };
  PublicClientApplication.prototype.loginPopup = function () {
    return Promise.resolve(testAuthenticationResult);
  };
  PublicClientApplication.prototype.loginRedirect = function () {
    return Promise.resolve();
  };
  PublicClientApplication.prototype.logoutRedirect = function () {
    return Promise.resolve();
  };
  PublicClientApplication.prototype.logoutPopup = function () {
    return Promise.resolve();
  };
  PublicClientApplication.prototype.ssoSilent = function () {
    return Promise.resolve(testAuthenticationResult);
  };
  PublicClientApplication.prototype.enableAccountStorageEvents = function () {};
  PublicClientApplication.prototype.disableAccountStorageEvents = function () {};
  PublicClientApplication.prototype.setActiveAccount = function () {};
  PublicClientApplication.prototype.getActiveAccount = function () {
    // this.controller.getEventHandler().emitEvent(EventType.ACQUIRE_TOKEN_SUCCESS, InteractionType.Silent, testAuthenticationResult);
    return testAccountInfo;
  };
  PublicClientApplication.prototype.initializeWrapperLibrary = function () {};
  PublicClientApplication.prototype.setNavigationClient = function () {};
  PublicClientApplication.prototype.hydrateCache = function () {
    return Promise.resolve();
  };
}

const authApp = new PublicClientApplication(publicClientConfig);
export default authApp;
