import {
  ApplicationModel,
  DocumentationLibraryModel,
  ContentAreaModel,
  ProjectStageModel,
  ContentCategoryModel,
  ServiceProductModel,
} from ".";
import { ContentBlockTranslation, ContentBlock } from "~/graphql";
import { HasTranslations, applyMixins } from "./mixins";

export interface IContentStateEntityImageNew {
  src: string;
  id: string;
  name: string;
}

export interface IContentStateEntityImage {
  src: string;
  alt: string;
  width: string;
  height: string;
}

export interface IContentStateEntityAttachment {
  type: string;
  url: string;
  name: string;
  filename: string;
  extension: string;
}

export interface IContentStateEntityLink {
  href: string;
  rel: string;
  target: string;
  url: string;
}

export type IContentStateEntityData = IContentStateEntityImageNew &
  IContentStateEntityImage &
  IContentStateEntityAttachment &
  IContentStateEntityLink;

export interface IContentStateEntity {
  type: string;
  mutability: string;
  data: IContentStateEntityData;
}

interface IContentStateBlock {
  key: string;
  text: string;
  type: string;
  depth: number;
  inlineStyleRanges: { offset: number; length: number; style: string }[];
  entityRanges: {
    offset: number;
    length: number;
    key: string;
  }[];
  data: any;
}

export interface IContentState {
  blocks: IContentStateBlock[];
  entityMap: { [key: string]: IContentStateEntity };
}

export class ContentBlockModel extends ApplicationModel {
  private _data!: ContentBlock;

  id: string;
  riskLevel?: string;
  frontpage: boolean;
  discarded: boolean;

  documentationLibrary?: DocumentationLibraryModel;
  contentArea?: ContentAreaModel;
  projectStage?: ProjectStageModel;
  contentCategory?: ContentCategoryModel;
  serviceProduct?: ServiceProductModel;

  createdAt: Date;
  updatedAt: Date;

  constructor(data: ContentBlock) {
    super();

    this._data = data;

    this.id = this._data.id;

    this.riskLevel = this._data.riskLevel || undefined;
    this.frontpage = this._data.frontpage;
    this.discarded = this._data.discarded;

    if (this._data.documentationLibrary)
      this.documentationLibrary = new DocumentationLibraryModel(
        this._data.documentationLibrary
      );
    if (this._data.contentArea)
      this.contentArea = new ContentAreaModel(this._data.contentArea!);
    if (this._data.projectStage)
      this.projectStage = new ProjectStageModel(this._data.projectStage!);
    if (this._data.contentCategory)
      this.contentCategory = new ContentCategoryModel(
        this._data.contentCategory!
      );
    if (this._data.serviceProduct)
      this.serviceProduct = new ServiceProductModel(this._data.serviceProduct!);

    // if (this._data.versions?.nodes) {
    //   this.versions = this._data.versions.nodes.map((version) => new ContentBlockVersionModel(version));
    // }

    this.createdAt = new Date(this._data.createdAt);
    this.updatedAt = new Date(this._data.updatedAt);
  }

  static initialize(id = "new"): ContentBlockModel {
    return new ContentBlockModel({
      id,
      frontpage: false,
      discarded: false,
      createdAt: "",
      updatedAt: "",
    });
  }
}
export interface ContentBlockModel
  extends HasTranslations<ContentBlockTranslation> {}
applyMixins(ContentBlockModel, [HasTranslations]);
