import React from 'react';
import { createRoot } from 'react-dom/client';
import { isGraphQLError } from '../lib/GraphQLError';
import CustomErrorFallback from './CustomErrorFallback';
import GraphQLErrorFallback from './GraphQLErrorFallback';

/**
 * Handles global JavaScript errors that occur outside of React's error boundary.
 * This function is typically called in the `componentDidCatch` lifecycle method
 * of the root `App` component or in a global error handler.
 *
 * @param {Error} error - The error object containing information about the error.
 * @param {string} [eventId] - An optional event ID associated with the error (e.g., from an error reporting service).
 *
 * @example
 * // In App.tsx
 * import { handleGlobalError } from './components/GlobalErrorHandler';
 *
 * class App extends React.Component {
 *   componentDidCatch(error, info) {
 *     handleGlobalError(error, info.componentStack);
 *   }
 *
 *   // ...
 * }
 *
 * @example
 * // In a global error handler
 * import { handleGlobalError } from './components/GlobalErrorHandler';
 *
 * window.onerror = function(message, source, lineno, colno, error) {
 *   handleGlobalError(error);
 * };
 */
export function handleGlobalError(error: Error) {
  const errorContainer = document.createElement('div');
  errorContainer.id = 'global-error-container';
  document.body.appendChild(errorContainer);

  const root = createRoot(errorContainer);

  const resetError = () => {
    root.unmount();
    errorContainer.remove();
    // ... rest of the reset logic (unchanged)
  };

  if (isGraphQLError(error)) {
    root.render(
      <React.StrictMode>
        <GraphQLErrorFallback error={error} resetError={resetError} />
      </React.StrictMode>
    );
  } else {
    root.render(
      <React.StrictMode>
        <CustomErrorFallback
          error={error}
          componentStack="Global error"
          eventId="unknown"
          resetError={resetError}
        />
      </React.StrictMode>
    );
  }
}
