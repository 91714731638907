import * as Sentry from "@sentry/react";
import type { Client, Integration } from "@sentry/types";
import { isLocal, sentryDsn, sentryEnv, sentryRelease } from "../env";

let sentry: Client | undefined;

// Extend the ClientOptions type to include the new properties
interface ExtendedClientOptions extends Sentry.ClientOptions<Sentry.BaseTransportOptions> {
  replaysSessionSampleRate?: number;
  replaysOnErrorSampleRate?: number;
}

export function initSentry() {
  if (isLocal) return;

  sentry = Sentry.init({
    dsn: sentryDsn,
    environment: sentryEnv,
    release: sentryRelease,
    ignoreErrors: [
      /InteractionRequiredAuthError/,
      /BrowserAuthError/,
      /ClientAuthError/,
    ],
    integrations: [],
    // related to performance monitoring of the app
    tracesSampleRate: 0.01,
  });
}

export function configureSentryWithFeatureFlags(enableReplays: boolean) {
  if (isLocal || !sentry) return;

  const integrations: Integration[] = enableReplays
    ? [
        Sentry.reactRouterV5BrowserTracingIntegration({
          useEffect: true,
          useLocation: true,
        }),
        Sentry.replayIntegration(),
      ]
    : [];

  const client = Sentry.getCurrentScope().getClient();
  if (client) {
    const options = client.getOptions() as ExtendedClientOptions;
    options.integrations = [
      ...options.integrations,
      ...integrations,
    ];

    if (enableReplays) {
      options.replaysSessionSampleRate = 0.1;
      options.replaysOnErrorSampleRate = 1.0;
    }
  }
}

export default sentry;
