import { gql, NetworkStatus, useQuery } from "@apollo/client";

export const BUILDING_FIELDS = gql`
  fragment BuildingFields on Building {
    id
    name
    buildingIdentifier
    addresses {
      id
      streetName
      streetNumber
      city
      zipCode
      editable
    }
    xCoordinate
    yCoordinate
    propertyIdentifier
    buildingStatus
    statusOfUse
    buildingCompletionYear
    lastChangeCompletedAt
    buildingUsageType
    buildingClassifications {
      id
      code
      name
    }
    mainBuildingClassification {
      id
      code
      name
    }
    apartmentCount
    floorCount
    buildingMaterial
    facadeMaterial
    heatingType
    heatingFuel
    sewer
    waterPipe
    repairRatePercentage
    protectedSite
    protectionLevel
    elevator
    electricity
    gas
    locationNotCertain
    combinedFloorArea
    combinedArea
    combinedRoomArea
    combinedLivingArea
    buildingVolume
    combinedCellarFloorArea
    vrkDataUpdatedAt
    hsyDataUpdatedAt
    treDataUpdatedAt
    jklDataUpdatedAt
    uneditableFields
  }
`;

/**
 * Search building and get its full building data
 * */
export const SEARCH_BUILDING = gql`
  ${BUILDING_FIELDS}
  query SearchBuilding($buildingIdentifier: String!) {
    searchBuilding(buildingIdentifier: $buildingIdentifier) {
      ...BuildingFields
      worksite {
        id
        name
      }
    }
  }
`;

/**
 * Fetch buidling data by address or building id
 * */
const SEARCH_BUILDINGS = gql`
  query SearchBuildings(
    $address: AddressSearchInputObject
    $propertyIdentifier: String
    $first: Int
    $after: String
  ) {
    searchBuildings(
      address: $address
      propertyIdentifier: $propertyIdentifier
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          name
          buildingIdentifier
          buildingUsageType
          addresses {
            id
            streetName
            streetNumber
            city
            zipCode
          }
          worksite {
            id
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
      }
    }
  }
`;
const GET_BUILDING_BY_BUILDING_ID = gql`
  ${BUILDING_FIELDS}
  query SearchBuilding($buildingIdentifier: String!) {
    searchBuilding(buildingIdentifier: $buildingIdentifier) {
      ...BuildingFields
      worksite {
        id
        name
      }
    }
  }
`;

function getBuildingCandidates(data, resultName) {
  if (resultName === "searchBuilding") {
    const building = data[resultName] ? { ...data[resultName] } : null;
    if (building) {
      building.addressesNames = getFullAddressesNames(building.addresses);
    }
    return building ? [building] : data ? [] : null;
  } else {
    return data[resultName]?.edges?.map((edge) => {
      const building = { ...edge.node };
      building.addressesNames = getFullAddressesNames(building.addresses);
      return building;
    });
  }
}

export function useSearchBuildings(searchBy: string, search: string) {
  const { queryName, paramName, resultName } = queryAttributes[searchBy];
  const {
    data = {},
    error,
    networkStatus,
    fetchMore,
  } = useQuery(queryName, {
    variables: { first: 40, [paramName]: search },
    skip: !search,
    fetchPolicy: "cache-and-network",
  });

  const result = getBuildingCandidates(data, resultName);

  return {
    result,
    error,
    pageInfo: data[resultName]?.pageInfo,
    loading: [NetworkStatus.loading, NetworkStatus.setVariables].includes(
      networkStatus
    ),
    fetching: networkStatus === NetworkStatus.fetchMore,
    fetchMore: () =>
      fetchMore({
        variables: {
          after: data[resultName]?.pageInfo.endCursor,
        },
      }),
  };
}

const queryAttributes = {
  searchByAddress: {
    queryName: SEARCH_BUILDINGS,
    paramName: "address",
    resultName: "searchBuildings",
  },
  searchByBuildingId: {
    queryName: GET_BUILDING_BY_BUILDING_ID,
    paramName: "buildingIdentifier",
    resultName: "searchBuilding",
  },
  searchByPropertyId: {
    queryName: SEARCH_BUILDINGS,
    paramName: "propertyIdentifier",
    resultName: "searchBuildings",
  },
  searchByMap: {
    queryName: GET_BUILDING_BY_BUILDING_ID,
    paramName: "buildingIdentifier",
    resultName: "searchBuilding",
  },
};

export function getFullAddressesNames(data) {
  return data
    ?.map((i) => `${i.streetName} ${i.streetNumber || "" } (${i.city}, ${i.zipCode})`)
    .join(", ");
}

/**
 * Fetch building classifications for using as options
 * */
const GET_BUILDING_CLASSIFICATIONS = gql`
  query BuildingClassifications($first: Int, $after: String) {
    buildingClassifications(first: $first, after: $after) {
      edges {
        node {
          id
          code
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
export function useBuildingClassifications() {
  const { data, loading, error } = useQuery(GET_BUILDING_CLASSIFICATIONS, {
    variables: { first: 200 },
    fetchPolicy: "cache-and-network",
  });

  return {
    data: getOptions(data?.buildingClassifications),
    loading,
    error,
  };
}
function getOptions(data) {
  const nodes = data?.edges.map((edge) => edge.node);
  return nodes?.map((i) => ({
    label: `${i.code} - ${i.name}`,
    value: i.id,
    code: i.code,
  }));
}

/**
 * update building data from Aino api
 * */
export const UPDATE_BUILDING_DATA_FROM_AINO = gql`
  mutation FetchBuildingUpdates($fetchBuildingUpdatesId: ID!) {
    fetchBuildingUpdates(id: $fetchBuildingUpdatesId) {
      id
      name
      buildingIdentifier
    }
  }
`;

/**
 * Add building identifier
 * */
export const ADD_BUILDING_IDENTIFIER = gql`
  mutation AddBuildingIdentifier(
    $buildingIdentifier: String!
    $addBuildingIdentifierId: ID!
  ) {
    addBuildingIdentifier(
      buildingIdentifier: $buildingIdentifier
      id: $addBuildingIdentifierId
    ) {
      id
      name
      buildingIdentifier
    }
  }
`;
