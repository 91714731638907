import { ApplicationModel, DocumentationLibraryModel } from ".";
import { HasTranslations, applyMixins } from "./mixins";
import {
  ContentAreaTypeEnum,
  DocumentationLibrary,
  KnowledgeArea,
  KnowledgeAreaTranslation,
} from "~/graphql";

export class KnowledgeAreaModel extends ApplicationModel {
  private _data!: KnowledgeArea;

  id: string;
  slug: string;
  name: string;
  description?: string;
  position?: number;
  draft: boolean;
  frontpage: boolean;
  contentBlocksCount: number;
  documentationLibrary?: DocumentationLibraryModel;
  createdAt: Date;
  updatedAt: Date;

  areaType = ContentAreaTypeEnum.KnowledgeArea;

  constructor(data: KnowledgeArea) {
    super();
    this._data = data;

    this.id = this._data.id;
    this.slug = this._data.slug;

    this.name = this._data.name;
    if (this._data.description) this.description = this._data.description;
    if (this._data.position) this.position = this._data.position;
    this.draft = this._data.draft;
    this.frontpage = this._data.frontpage;
    this.contentBlocksCount = this._data.contentBlocksCount;

    if (this._data.documentationLibrary)
      this.documentationLibrary = new DocumentationLibraryModel(
        this._data.documentationLibrary
      );

    this.createdAt = new Date(this._data.createdAt);
    this.updatedAt = new Date(this._data.updatedAt);
  }

  static initialize(id = "new"): KnowledgeAreaModel {
    return new KnowledgeAreaModel({
      id,
      slug: "",
      name: "",
      description: "",
      position: 0,
      draft: false,
      frontpage: false,
      contentBlocksCount: 0,
      documentationLibrary:
        DocumentationLibraryModel.initialize() as DocumentationLibrary,
      createdAt: "",
      updatedAt: "",
    });
  }
}
export interface KnowledgeAreaModel
  extends HasTranslations<KnowledgeAreaTranslation> {}
applyMixins(KnowledgeAreaModel, [HasTranslations]);
