import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import denormalize from "denormalize-jsonapi";
import actionReporter from "~/config/initializers/actionReporter";
import groupBy from "lodash.groupby";
import sortBy from "lodash.sortby";

import { buildFiltersQuery, reclamationNumber } from "../helpers";
import { useRestApiClient } from "~/lib/ClientProxy";

/**
 * Own reclamations
 * */
export function useOwnReclamations(filters) {
  const api = useRestApiClient();

  return useQuery(["own-reclamations", filters], fetchOwnReclamations, {
    refetchOnWindowFocus: false,
  });

  function fetchOwnReclamations() {
    const query = buildFiltersQuery(filters);
    return api
      .getOwnReclamations(query ? `/?${query}` : undefined)
      .then(({ data }) => {
        const own = denormalize(data, [
          "project",
          "project.owner",
          "creator",
          "responsible",
        ]).data;
        const ordered = sortBy(own, (i) => {
          return parseInt(i.id);
        });
        const grouped = groupBy(ordered, "project.id");
        return Object.entries(grouped);
      })
      .catch((error) => {
        console.log(error);
        actionReporter.notify(error);
      });
  }
}

/**
 * Reclamation
 * */
export function useReclamation(reclamationId) {
  const api = useRestApiClient();

  return useQuery(
    ["reclamation", reclamationId],
    () => fetchReclamation(api, reclamationId),
    {
      enabled: !!reclamationId,
      refetchOnWindowFocus: false,
    }
  );
}

function fetchReclamation(api, reclamationId) {
  return api
    .getReclamation(reclamationId)
    .then(({ data }) => {
      const denormalized = denormalize(data, [
        "project",
        "project.owner",
        "creator",
        "responsible",
      ]);
      return {
        reclamation: denormalized.data,
        error: !denormalized?.data,
      };
    })
    .catch((error) => {
      actionReporter.notify(error);
      return {
        reclamation: null,
        error: error,
      };
    });
}

/**
 * Reclamations
 * */
export function useProjectReclamations(projectIdentifier, legacy = true) {
  const api = useRestApiClient();
  const { t } = useTranslation();

  return useQuery(
    ["reclamations", projectIdentifier],
    () => fetchReclamations(api, projectIdentifier, t, legacy),
    {
      enabled: !!projectIdentifier,
      refetchOnWindowFocus: false,
    }
  );
}

function fetchReclamations(api, projectIdentifier, t, legacy) {
  return api
    .getProjectReclamations(projectIdentifier, legacy)
    .then(({ data }) => {
      const denormalized = denormalize(data, [
        "project",
        "creator",
        "responsible",
      ]);
      return {
        data: sortBy(denormalized.data, (i) =>
          typeof i.id === "string" ? parseInt(i.id) : i.id
        ),
        meta: denormalized.meta,
        error: null,
      };
    })
    .catch((error) => {
      actionReporter.notify(error);
      console.log("Reklamaatioiden lataaminen ei onnistunut", error);

      return {
        data: null,
        error:
          error.status === 403
            ? t("Errors.noAccess")
            : t("Errors.couldNotFind"),
      };
    });
}

/**
 * Reclamations numbers
 * */
export function getReclamationsNumbers(result, t) {
  if (result?.data) {
    const reclamations = result.data.map((i) => ({
      id: i.id,
      project: { identifier: i.project?.identifier },
    }));
    return {
      data: reclamations.map((i) => reclamationNumber(i)).join(", "),
      error: null,
    };
  } else {
    return {
      data: null,
      error: result?.error ? result.error : t("Errors.couldNotFind"),
    };
  }
}
