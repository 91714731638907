import * as Sentry from '@sentry/react';
import React, { useRef, useState } from 'react';
import ErrorFeedback from './ErrorFeedback';

interface Error {
  id: string;
  message: string;
  stack?: string;
}

interface CustomErrorFallbackProps {
  error: unknown;
  componentStack: string;
  eventId: string;
  resetError(): void;
}

const CustomErrorFallback = (props: CustomErrorFallbackProps) => {
  const {error, componentStack, eventId, resetError } = props;
  const contentRef = useRef<HTMLDivElement>(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showStackTrace, setShowStackTrace] = useState(false);
  const [showDashboardAlert, setShowDashboardAlert] = useState(false);

  const handleMoreDetails = () => {
    setShowFeedback(true);
  };

  const handleFeedbackSubmit = (feedback: { action: string; context: string }) => {
    // submit user generated error feedback to sentry
    const { action, context } = feedback;
    Sentry.captureException(error, {
      eventId,
      comments: `Action taken: ${action}\n\nAdditional Information: ${context}`
    });
    setShowFeedback(false);
  };

  const handleFeedbackCancel = () => {
    setShowFeedback(false);
  };

  const handleGoToDashboard = () => {
    setShowDashboardAlert(true);
  };

  const confirmGoToDashboard = () => {
    window.location.href = '/dashboard';  // Adjust this path as needed
  };

  return (
    <>
      <div
        className={`fixed top-0 left-0 right-0 bg-blue-50 text-blue-800 shadow-md transition-all duration-300 ease-in-out overflow-hidden`}
      >
        <div className="max-w-4xl mx-auto p-3" ref={contentRef}>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <svg className="w-4 h-4 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                <path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
              <span className="font-semibold text-sm">
                Oops, something didn't go as expected...
              </span>
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                resetError();
              }}
              className="text-blue-600 hover:text-blue-800 focus:outline-none text-sm"
              aria-label="Dismiss"
            >
              ✕
            </button>
          </div>
          <div className="mt-2">
            <p className="text-xs mb-2">We're working on fixing this. Here's what you can do:</p>
            <div className="flex space-x-2 mb-2">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  resetError();
                }}
                className="px-2 py-1 bg-blue-100 text-blue-800 rounded hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-300 text-xs"
              >
                Try to continue
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleMoreDetails();
                }}
                className="px-2 py-1 bg-blue-100 text-blue-800 rounded hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-300 text-xs"
              >
                Help us improve
              </button>
              <button
                onClick={handleGoToDashboard}
                className="px-2 py-1 bg-blue-100 text-blue-800 rounded hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-300 text-xs"
              >
                Go to Dashboard
              </button>
            </div>


            <div className="mt-2 text-xxs text-gray-600 mt-8">
              {error?.cause?.name !== undefined ? error.cause.name : "An unexpected error occurred"}
            </div>
            <button
              onClick={() => setShowStackTrace(!showStackTrace)}
              className="mt-2 text-xxxs text-blue-600 hover:text-blue-800 focus:outline-none"
            >
              {showStackTrace ? 'Hide' : 'Show'} technical details
            </button>
            {showStackTrace && (
              <div className="mt-2 bg-blue-100 p-2 rounded">
                 <pre>
                {componentStack}
                 </pre>
              </div>
            )}
          </div>
        </div>
      </div>
      {showFeedback && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <ErrorFeedback
            onSubmit={handleFeedbackSubmit}
            onCancel={handleFeedbackCancel}
          />
        </div>
      )}
      {showDashboardAlert && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-md">
            <h3 className="text-lg font-semibold mb-4">Are you sure you want to go to the dashboard?</h3>
            <p className="text-sm text-gray-600 mb-4">
              You may lose any unsaved work on this page. It's recommended to save your progress if possible before proceeding.
            </p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setShowDashboardAlert(false)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
              >
                Stay here
              </button>
              <button
                onClick={confirmGoToDashboard}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomErrorFallback;
