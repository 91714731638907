import i18n from "../config/initializers/i18n";
import { ApolloClientProxy } from "./ApolloClientProxy";
import MsGraphClientProxy from "./MsGraphClientProxy";
import Api from "./RestApiClient";

let clientProxyInstance: ClientProxy | null = null;

/**
 * Factory function to create and manage a singleton instance of ClientProxy.
 * @returns ClientProxy The singleton instance of ClientProxy.
 * @see ClientProxy
 */

export const createClientProxy = (): ClientProxy => {
  if (!clientProxyInstance) {
    clientProxyInstance = new ClientProxy();
  }
  return clientProxyInstance;
};

/***
 * ClientProxy class. Only create an instance via createClientProxy
 * @see createClientProxy
 */
class ClientProxy {
  restApiClient!: Api;
  apolloClientProxy!: ApolloClientProxy;
  msGraphClientProxy!: MsGraphClientProxy;
  private accessToken: string;

  constructor() {
    this.accessToken = "";
    this.restApiClient = new Api({
      fetchAccessToken: this.fetchAccessToken.bind(this, "restApiClient"),
      fetchLocale: this.fetchLocale.bind(this, "restApiClient"),
    });
    this.apolloClientProxy = new ApolloClientProxy({
      fetchAccessToken: this.fetchAccessToken.bind(this, "apolloClientProxy"),
      fetchLocale: this.fetchLocale.bind(this, "apolloClientProxy"),
    });
    this.msGraphClientProxy = new MsGraphClientProxy({
      fetchAccessToken: this.fetchAccessToken.bind(this, "msGraphClientProxy"),
      fetchLocale: this.fetchLocale.bind(this, "msGraphClientProxy"),
    });
  }

  get apolloClient() {
    return this.apolloClientProxy.client;
  }

  get msGraphClient() {
    return this.msGraphClientProxy.client;
  }

  setAccessToken(accessToken: string) {
    this.accessToken = accessToken;
  }

  /**
   * Fetches the access token for the specified client type.
   * @param {string} caller - The type of client requesting the access token.
   * @returns {string} The access token.
   */

  fetchAccessToken(caller: string): string {
    return this.accessToken;
  }
  /**
   * Fetches the locale for the specified client type.
   * @param {string} caller - The type of client requesting the locale.
   * @returns {string} The locale.
   */

  fetchLocale(caller: string): string {
    return i18n.language;
  }
}

import { createContext, useContext } from "react";

export const ClientProxyContext = createContext<ClientProxy | undefined>(
  undefined
);
export const useClientProxy = () => {
  const context = useContext(ClientProxyContext);
  if (context === undefined) {
    console.warn(
      "useClientProxy called outside of ClientProxyProvider, creating a new ClientProxy instance."
    );
    return createClientProxy();
  }
  return context;
};

export const useRestApiClient = () => useClientProxy().restApiClient;
export const useApolloClient = () => useClientProxy().apolloClient;
export const useMsGraphClient = () => useClientProxy().msGraphClient;
