import { ApplicationModel, DocumentationLibraryModel } from ".";
import {
  ContentCategoryColorEnum,
  ContentCategory,
  ContentCategoryTranslation as IContentCategoryTranslation,
  DocumentationLibrary,
} from "~/graphql";
import { HasTranslations, applyMixins } from "./mixins";

export class ContentCategoryModel extends ApplicationModel {
  private _data!: ContentCategory;

  id: string;
  slug?: string;
  name?: string;
  description?: string;
  color: ContentCategoryColorEnum;
  home?: boolean;
  position?: number;
  contentBlocksCount: number;
  documentationLibrary?: DocumentationLibraryModel;
  createdAt: Date;
  updatedAt: Date;

  constructor(data: ContentCategory) {
    super();
    this._data = data;

    this.id = this._data.id;
    this.slug = this._data.slug;
    this.name = this._data.name || undefined;
    this.description = this._data.description || undefined;
    this.color = this._data.color;
    this.home = this._data.home || false;
    this.position = this._data.position || 0;
    this.contentBlocksCount = this._data.contentBlocksCount;
    if (this._data.documentationLibrary)
      this.documentationLibrary = new DocumentationLibraryModel(
        this._data.documentationLibrary
      );

    this.createdAt = new Date(this._data.createdAt);
    this.updatedAt = new Date(this._data.updatedAt);
  }

  static initialize(id = "new"): ContentCategoryModel {
    return new ContentCategoryModel({
      id,
      slug: "",
      name: "",
      description: "",
      color: ContentCategoryColorEnum.Blue,
      home: false,
      position: 0,
      contentBlocksCount: 0,
      documentationLibrary:
        DocumentationLibraryModel.initialize() as DocumentationLibrary,
      createdAt: "",
      updatedAt: "",
    });
  }
}
export interface ContentCategoryModel
  extends HasTranslations<IContentCategoryTranslation> {}
applyMixins(ContentCategoryModel, [HasTranslations]);
