import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import SubNavLink from "../components/Navigation/SubNavLink";
import SecurityContext from "../components/SecurityContext";

import LanguageSwitcher from "../components/LanguageSwitcher";
import NaviUserInfo from "../components/NaviUserInfo";

import styles from "./ProjectNavigation.module.css";

export default function ProjectNavigation({
  project,
  policies,
  rootPath,
  showDocsTab,
  userInfo,
  logOut,
}) {
  const { t } = useTranslation();
  const { superadmin } = useContext(SecurityContext);

  return (
    <div className={styles.subNavigation}>
      <SubNavLink
        exact
        label={t("Navigation.basicInformation")}
        url={rootPath}
        type="projects"
      />
      {project.projectChecklistGenerated && (
        <SubNavLink
          label={t("Navigation.checklist")}
          url={`${rootPath}/checklist`}
          type="projects"
        />
      )}
      <SubNavLink
        label={t("Navigation.workspaceTools")}
        url={`${rootPath}/workspace_tools`}
        disabled={!policies?.project?.showWorkspaceTools}
        type="projects"
      />
      <SubNavLink
        label={t("Navigation.riskAssessments")}
        url={`${rootPath}/risk_assessments`}
        disabled={!policies?.riskAssessment?.index}
        type="projects"
      />
      <SubNavLink
        label={t("Navigation.responsibility")}
        url={`${rootPath}/responsibility_questionnaire`}
        disabled={!policies?.project?.showResponsibilityForm}
        type="projects"
      />
      <SubNavLink
        label={t("Navigation.reclamations")}
        url={`${rootPath}/reclamations`}
        disabled={!policies?.reclamation?.index}
        type="projects"
      />
      <SubNavLink
        label={t("Navigation.projectFeedback")}
        url={`${rootPath}/feedbacks`}
        disabled={!policies?.survey?.index}
        type="projects"
      />
      <SubNavLink
        label={t("Navigation.guidelines")}
        url={`${rootPath}/services_documentations`}
        disabled={!showDocsTab}
        type="projects"
      />
      <SubNavLink
        label={t("Navigation.reference")}
        url={`${rootPath}/reference`}
        type="projects"
      />
      {superadmin && (
        <SubNavLink
          label={t("Navigation.admin")}
          url={`${rootPath}/admin`}
          type="projects"
        />
      )}
      <div className="marginTopAuto paddingTop">
        <LanguageSwitcher />
        <NaviUserInfo userInfo={userInfo} logOut={logOut} />
      </div>
    </div>
  );
}
