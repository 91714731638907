import * as React from "react";
import { useTranslation } from "react-i18next";

import LanguageSwitcher from "../../components/LanguageSwitcher";
import NaviUserInfo from "../../components/NaviUserInfo";
import SubNavLink from "../../components/Navigation/SubNavLink";

import styles from "./ReferenceGroupNavigation.module.css";

export default function ReferenceGroupNavigation({
  rootPath,
  userInfo,
  logOut,
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.subNavigation}>
      <SubNavLink
        exact
        label={t("Navigation.referenceGroupInformation")}
        url={rootPath}
        type="referenceGroups"
      />
      <SubNavLink
        label={t("Navigation.workspaces")}
        url={`${rootPath}/workspaces`}
        type="referenceGroups"
      />
      {/* <SubNavLink
        label={t("Navigation.references")}
        url={`${rootPath}/references`}
        type="referenceGroups"
      /> */}
      <SubNavLink
        label={t("Navigation.employees")}
        url={`${rootPath}/employees`}
        type="referenceGroups"
      />

      <div className="marginTopAuto paddingTop">
        <LanguageSwitcher />
        <NaviUserInfo userInfo={userInfo} logOut={logOut} />
      </div>
    </div>
  );
}
