export class ApplicationModel {
  private _cache: { [key: string]: any } = {};

  get newRecord(): boolean {
    return !this["id"] || this["id"].startsWith("new");
  }

  getCache<T>({ key }: { key: string }): T {
    return this._cache[key];
  }

  setCache<T>({ key, value }: { key: string; value: T }): void {
    this._cache[key] = value;
  }

  fetchCache<T>({
    key,
    fetch,
    refresh = false,
  }: {
    key: string;
    fetch: () => T;
    refresh?: boolean;
  }): T {
    let value = this.getCache<T>({ key });
    if (!value || refresh) {
      value = fetch();
      this.setCache<T>({ key, value });
    }
    return value;
  }
}
