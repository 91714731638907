import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import actionReporter from "~/config/initializers/actionReporter";

import { transformData } from "../RiskAssessment/RiskAssessmentHelper";
import { useRestApiClient } from "~/lib/ClientProxy";

export function useRiskAssessment(riskAssessmentId) {
  const { t } = useTranslation();
  const api = useRestApiClient();

  return useQuery(
    ["risk-assessment", riskAssessmentId],
    () =>
      riskAssessmentId ? fetchRiskAssessment(api, riskAssessmentId, t) : {},
    {
      refetchOnWindowFocus: false,
    }
  );
}

function fetchRiskAssessment(api, riskAssessmentId, t) {
  return api
    .getRiskAssessment(riskAssessmentId)
    .then(({ data }) => {
      const riskA = transformData({ data });
      if (riskA?.id) {
        return {
          data: riskA,
          error: null,
        };
      } else {
        return {
          data: null,
          error: t("Errors.couldNotLoadRiskAssessment"),
        };
      }
    })
    .catch((error) => {
      actionReporter.notify(error);
      return {
        data: null,
        error: t("Errors.couldNotLoadRiskAssessment"),
      };
    });
}
