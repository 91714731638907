import * as React from "react";
import { createPortal } from "react-dom";

import styles from "./NewVoimaVersionToast.module.css";
import { useTranslation } from "react-i18next";

const NewVoimaVersionToast = () => {
  const { t } = useTranslation();

  const notificationsElement = document.getElementById("notifications");
  if (!notificationsElement) {
    console.error("Element for notifications is not found");
    return null;
  }

  const toastElement = document.createElement("div");
  notificationsElement.appendChild(toastElement);

  return createPortal(
    <div className={styles.toast}>
      <div className={styles.toastBody}>
        {String(t("General.newVoimaVersionAvailable"))}
      </div>
      <button
        className={styles.toastRefreshButton}
        onClick={() => window.location.reload()}
      >
        {String(t("General.reloadPage"))}
      </button>
    </div>,
    toastElement
  );
};
export default NewVoimaVersionToast;
