import {
  ApplicationModel,
  DocumentationLibraryModel,
  ServiceAreaModel,
  ServiceProductModel,
} from ".";
import { HasTranslations, applyMixins } from "./mixins";
import {
  ContentAreaTypeEnum,
  ServiceType,
  ServiceTypeTranslation,
} from "~/graphql";

export class ServiceTypeModel extends ApplicationModel {
  private _data!: ServiceType;

  id: string;
  slug: string;

  name: string;
  code: string;

  contentBlocksCount: number;
  position: number | null | undefined;

  serviceAreaId: number;
  serviceArea?: ServiceAreaModel;
  serviceProducts?: ServiceProductModel[];

  documentationLibrary?: DocumentationLibraryModel;

  createdAt: Date;
  updatedAt: Date;

  areaType = ContentAreaTypeEnum.ServiceType;

  constructor(data: ServiceType) {
    super();
    this._data = data;

    this.id = this._data.id;
    this.slug = this._data.slug;

    this.name = this._data.name;
    this.code = this._data.code;

    this.contentBlocksCount = this._data.contentBlocksCount;
    this.position = this._data.position;

    this.serviceAreaId = this._data.serviceAreaId;
    if (this._data.serviceArea)
      this.serviceArea = new ServiceAreaModel(this._data.serviceArea);
    if (this._data.serviceProducts)
      this.serviceProducts = this._data.serviceProducts.map(
        (serviceProduct) => new ServiceProductModel(serviceProduct)
      );

    if (this._data.documentationLibrary)
      this.documentationLibrary = new DocumentationLibraryModel(
        this._data.documentationLibrary
      );

    this.createdAt = new Date(this._data.createdAt);
    this.updatedAt = new Date(this._data.updatedAt);
  }
}
export interface ServiceTypeModel
  extends HasTranslations<ServiceTypeTranslation> {}
applyMixins(ServiceTypeModel, [HasTranslations]);
