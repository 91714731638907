import React, { useState } from 'react';
import ErrorFeedback from './ErrorFeedback';

interface GraphQLErrorFallbackProps {
  error: Error;
  resetError(): void;
}

const GraphQLErrorFallback = ({ error, resetError }: GraphQLErrorFallbackProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  const handleFeedbackSubmit = (feedback: { action: string; context: string }) => {
    // submit user generated error feedback
    console.log('Submitting feedback:', feedback);
    setShowFeedback(false);
  };

  const handleFeedbackCancel = () => {
    setShowFeedback(false);
  };

  const renderErrorDetails = () => (
    <div className="mt-2 bg-blue-100 p-2 rounded">
      <pre>
        {error.message}<br />
<br />
        {JSON.stringify(error, null, 2)}<br />
<br />
        {error.stack}
      </pre>
    </div>
  );

  return (
    <div className="fixed top-0 left-0 right-0 bg-blue-50 text-blue-800 shadow-md transition-all duration-300 ease-in-out overflow-hidden z-50">
      <div className="max-w-4xl mx-auto p-3">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <svg className="w-4 h-4 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            <span className="font-semibold text-sm">Data was not as expected</span>
          </div>
          <button
            onClick={resetError}
            className="text-blue-600 hover:text-blue-800 focus:outline-none text-sm"
            aria-label="Dismiss"
          >
            ✕
          </button>
        </div>
        <div className="mt-2">
          <p className="text-xs mb-2">{error.message}</p>
          <button
            onClick={() => setShowDetails(!showDetails)}
            className="mt-2 text-xxxs text-blue-600 hover:text-blue-800 focus:outline-none"
          >
            {showDetails ? 'Hide' : 'Show'} technical details
          </button>
          {showDetails && renderErrorDetails()}
        </div>
      </div>
      {showFeedback && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <ErrorFeedback
            onSubmit={handleFeedbackSubmit}
            onCancel={handleFeedbackCancel}
          />
        </div>
      )}
    </div>
  );
};

export default GraphQLErrorFallback;
