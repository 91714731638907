import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function ScrollToTop() {
  const { pathname, hash, key } = useLocation();
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      setIsScrolling(true);
      setTimeout(() => {
        const element = document.getElementById(hash.slice(1));
        if (element) {
          element.scrollIntoView();
          window.scrollBy(0, -84);
        }
        setIsScrolling(false);
      }, 700);
    }
  }, [pathname, hash, key]);

  return isScrolling ? <div className="scroll-indicator" /> : null;
}
