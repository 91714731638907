import React from "react";

import * as Sentry from "@sentry/react";

import sentry from "~/config/initializers/sentry";
import CustomErrorFallback from '../../components/CustomErrorFallback';

export default class SentryReporter {
  private instance: any;

  transactionId: string;

  constructor() {
    this.instance = sentry;

    this.transactionId = this.generateTransactionId();
  }

  generateTransactionId(seed?: string) {
    this.transactionId = btoa([seed, Math.random(), Date.now()].join("-"));
    Sentry.setTag("transactionId", this.transactionId);
    return this.transactionId;
  }

  notify(message: string, { context }: { context?: Record<string, any> } = {}) {
    return Sentry.captureException(message, { captureContext: context });
  }

  setContext(context: Record<string, any>) {
    if (context["voimaUserId"] && context["voimaVersion"]) {
      this.generateTransactionId(`${context["voimaVersion"]}-${context["voimaUserId"]}`);
    }
    return Object.keys(context).map((key) => {
      Sentry.setContext(key, context[key]);
    });
  }

  resetContext() {
    return Sentry.getCurrentScope().clear();
  }

  errorBoundary({ children }: { children: React.ReactNode }): React.ReactElement {
    return (
      <Sentry.ErrorBoundary fallback={CustomErrorFallback}>
        {children}
      </Sentry.ErrorBoundary>
    );
  }
}

