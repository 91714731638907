import * as React from "react";
import styles from "./UserInfo.module.css";

function UserInfo({ displayName, mail, o365Id }) {
  return displayName ? (
    <span title={[mail, o365Id].join(", ")} className={styles.name}>
      {displayName}
    </span>
  ) : (
    <span>Kirjaudutaan…</span>
  );
}

export default UserInfo;
