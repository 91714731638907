import React, { useState, useRef, useEffect } from 'react';

const MAX_COMMENT_LENGTH = 1000;

const Button = ({ children, onClick, variant = 'default', disabled = false }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`px-4 py-2 rounded ${
      variant === 'outline'
        ? 'border border-gray-300 text-gray-700'
        : 'bg-blue-500 text-white'
    } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
  >
    {children}
  </button>
);

const TextArea = ({ value, onChange, placeholder, rows = 4, onKeyDown }) => (
  <textarea
    value={value}
    onChange={onChange}
    onKeyDown={onKeyDown}
    placeholder={placeholder}
    rows={rows}
    className="w-full p-2 border border-gray-300 rounded"
  />
);

const RadioGroup = ({ options, selected, onChange }) => (
  <div className="space-y-2">
    {options.map((option) => (
      <label key={option.value} className="flex items-center">
        <input
          type="radio"
          value={option.value}
          checked={selected === option.value}
          onChange={() => onChange(option.value)}
          className="mr-2"
        />
        {option.label}
      </label>
    ))}
  </div>
);

interface ErrorFeedbackProps {
  onSubmit: (feedback: { action: string; context: string }) => void;
  onCancel: () => void;
}

const ErrorFeedback: React.FC<ErrorFeedbackProps> = ({ onSubmit, onCancel }) => {
  const [action, setAction] = useState('loading');
  const [context, setContext] = useState('');
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const handleSubmit = () => {
    onSubmit({ action, context });
    // reset state
    setAction('loading');
    setContext('');
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      submitButtonRef.current?.click();
    }
  };

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onCancel();
      }
    };
    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [onCancel]);

  const actionOptions = [
    { value: 'loading', label: 'The page was loading or refreshing' },
    { value: 'clicking', label: 'I was clicking a button or link' },
    { value: 'typing', label: 'I was typing or entering information' },
    { value: 'other', label: 'Something else' }
  ];

  const getContextPrompt = () => {
    switch(action) {
      case 'clicking':
        return "What were you trying to do? (e.g., 'Submitting a form', 'Opening a menu')";
      case 'typing':
        return "What were you entering? (e.g., 'My email address', 'A search query')";
      case 'other':
        return "Can you briefly describe what you were doing?";
      default:
        return "";
    }
  };

  return (
    <div className="p-4 bg-white rounded shadow max-w-lg relative">
      <button
        onClick={onCancel}
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        aria-label="Close"
      >
        ✕
      </button>
      <h3 className="text-lg font-semibold mb-2">Help Us Fix This Quickly</h3>
      <p className="text-sm text-gray-600 mb-4">
        We apologize for the error. Your input will help us resolve this issue faster. It'll take less than a minute.
      </p>

      <div className="mb-4">
        <p className="font-medium mb-2">What were you doing when the error occurred?</p>
        <RadioGroup
          options={actionOptions}
          selected={action}
          onChange={(value: string) => {
            setAction(value);
            setContext('');
          }}
        />
      </div>

      {action !== 'loading' && (
        <div className="mb-4">
          <p className="font-medium mb-2">{getContextPrompt()}</p>
          <TextArea
            value={context}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setContext(e.target.value.slice(0, MAX_COMMENT_LENGTH))}
            onKeyDown={handleKeyDown}
            placeholder="Please provide a brief description"
            rows={2}
          />
          <p className="text-xs text-gray-500 mt-1">
            {context.length}/{MAX_COMMENT_LENGTH} characters
          </p>
        </div>
      )}

      <p className="text-sm text-gray-600 mb-4">
        This information helps us pinpoint the exact cause and fix it faster. Thank you for your help in improving the app!
      </p>

      <div className="flex justify-end space-x-2 mt-4">
        <Button onClick={onCancel} variant="outline">Cancel</Button>
        <Button
          onClick={handleSubmit}
          ref={submitButtonRef}
        >
          Send Details to Fix Issue
        </Button>
      </div>
    </div>
  );
};

export default ErrorFeedback;
