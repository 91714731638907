import { ApplicationModel, DocumentationLibraryModel } from ".";
import { HasTranslations, applyMixins } from "./mixins";
import {
  DocumentationLibrary,
  ProjectStage,
  ProjectStageTranslation,
} from "~/graphql";

export class ProjectStageModel extends ApplicationModel {
  private _data!: ProjectStage;

  id: string;
  slug: string;
  name: string;
  description?: string;
  draft: boolean;
  frontpage: boolean;
  position?: number;
  contentBlocksCount: number;
  documentationLibrary?: DocumentationLibraryModel;
  createdAt: Date;
  updatedAt: Date;

  constructor(data: ProjectStage) {
    super();
    this._data = data;

    this.id = this._data.id;
    this.slug = this._data.slug;

    this.name = this._data.name;
    if (this._data.description) this.description = this._data.description;
    this.draft = this._data.draft;
    this.frontpage = this._data.frontpage;
    this.contentBlocksCount = this._data.contentBlocksCount;

    if (this._data.position) this.position = this._data.position;

    if (this._data.documentationLibrary)
      this.documentationLibrary = new DocumentationLibraryModel(
        this._data.documentationLibrary
      );

    this.createdAt = new Date(this._data.createdAt);
    this.updatedAt = new Date(this._data.updatedAt);
  }

  static initialize(id = "new"): ProjectStageModel {
    return new ProjectStageModel({
      id,
      slug: "",
      name: "",
      description: "",
      draft: false,
      frontpage: false,
      position: 0,
      contentBlocksCount: 0,
      documentationLibrary:
        DocumentationLibraryModel.initialize() as DocumentationLibrary,
      createdAt: "",
      updatedAt: "",
    });
  }
}
export interface ProjectStageModel
  extends HasTranslations<ProjectStageTranslation> {}
applyMixins(ProjectStageModel, [HasTranslations]);
