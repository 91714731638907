import * as React from "react";
import { useTranslation } from "react-i18next";

import { lngs } from "../config/i18n";
import { setLangAsync } from "../helpers";

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();

  function editLanguage(lng) {
    setLangAsync(lng);
    i18n.changeLanguage(lng);
  }

  return (
    <div className="flex childMarginsHalfX">
      {Object.keys(lngs).map((lng) => (
        <button
          key={lng}
          className={i18n.language === lng ? "bold" : ""}
          type="submit"
          onClick={() => editLanguage(lng)}
        >
          {lngs[lng].nativeName}
        </button>
      ))}
    </div>
  );
}
