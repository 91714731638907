import denormalize from "denormalize-jsonapi";
import omit from "lodash.omit";
import compact from "lodash.compact";

function transformData({ data, form }) {
  const denormalizedData = getDenormalizedData(data);
  let riskA =
    denormalizedData && omit(denormalizedData, ["type", "formGroups"]);
  let otherRisks = [];

  if (denormalizedData.formGroups && denormalizedData.formGroups.length) {
    let k = 1;
    denormalizedData.formGroups.forEach((item) => {
      const isOtherRisk = item.category && item.category === "otherThings";
      let riskName = item.name;
      if (isOtherRisk) {
        otherRisks.push(item.name);
        const foundInForm =
          form &&
          Object.keys(form).find((key) => {
            return form[key].name === item.name;
          });
        riskName = foundInForm || "other" + k;
        k += 1;
      }
      riskA[riskName] = {
        id: item.id,
        name: isOtherRisk ? item.name : null,
        riskMagnitude: item.magnitude || "",
        additionalDescription: item.description || "",
        actionInstructions: "",
        actions: item.actions || "",
        public: item.isPublic || "false",
        probability: item.probability || "",
        maxPrice: item.maxPrice || "",
        reservation: item.reservation || "",
      };
    });
  }
  riskA.otherRisks = otherRisks;

  return riskA;
}

function getFrameData(data) {
  const denormalizedData = getDenormalizedData(data);
  return {
    riskAssessment: {
      formGroups: denormalizedData.formGroups.map((item) => {
        return omit(item, ["id", "type", "createdAt"]);
      }),
    },
  };
}

function getDenormalizedData(data) {
  const denormalized =
    data &&
    denormalize(data, [
      "project",
      "offer",
      "creator",
      "responsible",
      "formGroups",
      "approval",
    ]);
  return denormalized?.data;
}

function getRiskId(form, riskAssessment, key) {
  if (riskAssessment[key]) {
    return riskAssessment[key].id;
  } else {
    let i = 1;
    let foundRisk;
    while (i < 6) {
      if (
        riskAssessment["other" + i] &&
        riskAssessment["other" + i].name === form[key].name
      ) {
        foundRisk = riskAssessment["other" + i];
        break;
      }
      i++;
    }
    if (foundRisk && foundRisk.id) {
      form[key].id = foundRisk.id;
      return foundRisk.id;
    }
  }
}

function getData(form, fields, riskAssessment) {
  const formGroups = compact(
    Object.keys(form).map((key) => {
      return {
        id: form[key].id || getRiskId(form, riskAssessment, key),
        category: fields && fields[key] ? fields[key].group : "otherThings",
        name: form[key].name || key,
        magnitude: form[key].riskMagnitude,
        description: form[key].additionalDescription,
        isPublic: form[key].public,
        actions: form[key].actions,
        probability: form[key].probability,
        maxPrice: form[key].maxPrice,
        reservation: form[key].reservation,
      };
    })
  );
  return {
    riskAssessment: {
      formGroups: formGroups,
      isPublished: true,
    },
  };
}

function getChangedData({ form, fields, currentGroup, riskAssessment }) {
  const changed = [];

  // get fields that are for this current group
  const currentFields = [];
  let i = 1;
  if (currentGroup === "otherThings") {
    while (i < 6) {
      currentFields.push("other" + i);
      i++;
    }
  } else {
    Object.keys(fields).forEach((key) => {
      if (fields[key]?.group === currentGroup) {
        currentFields.push(key);
      }
    });
  }

  // get form values and riskAssessment values. then compare them
  currentFields.forEach((key) => {
    const { formValue, raValue } = getValues(form, riskAssessment, key);
    // if raValue doesnt exist? should i pass formValue?
    if (formValue && (!raValue || compare(raValue, formValue))) {
      changed.push({
        id: formValue.id || getRiskId(form, riskAssessment, key),
        category: fields && fields[key] ? fields[key].group : "otherThings",
        name: formValue.name || key,
        magnitude: formValue.riskMagnitude,
        description: formValue.additionalDescription,
        isPublic: formValue.public,
        actions: formValue.actions,
        probability: formValue.probability,
        maxPrice: formValue.maxPrice,
        reservation: formValue.reservation,
      });
    }
  });

  return changed;
}

function getValues(form, riskAssessment, key) {
  let formValue = form[key];
  let raValue = riskAssessment[key];
  if (
    key.indexOf("other") > -1 &&
    formValue?.name &&
    riskAssessment.otherRisks?.length
  ) {
    const otherRisks = [];
    let i = 1;
    while (i < 6) {
      if (
        riskAssessment.otherRisks.includes(riskAssessment["other" + i]?.name)
      ) {
        otherRisks.push(riskAssessment["other" + i]);
      }
      i++;
    }
    raValue = otherRisks.find((i) => i.name === formValue?.name);
  }

  return { formValue, raValue };
}

function compare(obj1, obj2) {
  let different = false;
  Object.keys(obj1).forEach((key) => {
    if (key === "public") {
      if (obj1[key].toString() !== obj2[key].toString()) {
        different = true;
      }
    } else if (
      obj1[key] !== obj2[key] &&
      !["id", "actionInstructions"].includes(key)
    ) {
      different = true;
    }
  });
  return different;
}

export { transformData, getFrameData, getRiskId, getData, getChangedData };
