import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import groupBy from "lodash.groupby";
import uniq from "lodash.uniq";
import { BUILDING_FIELDS } from "./buildingHooks";

/**
 * Fetch worksite information
 * */
const WORKSPACE_FIELDS = gql`
  fragment WorkspaceFields on Workspace {
    id
    identifier
    valueframeId
    name
    clientName
    buildingLocationAddress
    mapLocationExists
    mapLocationAddress
    department {
      id
      code
      name
      businessAreaName
    }
    owner {
      name
      email
    }
    status
    teamsToolExternalLinkAvailable
    serviceDirectoriesLocationEnabled
    plannerToolExternalLinkAvailable
    valueframeToolExternalLinkAvailable
    networkDriveToolExternalLinkAvailable
    unit4ToolExternalLinkAvailable
    endUserDirectoryPath
    serviceDirectoriesLocationEnabled
    legacyNetworkDirectoryPath
    legacySharedTeamsUrl
    askiToolExternalLinkAvailable
    endUserDirectoryPath
    buildings {
      id
    }
    opportunityProjectManager {
      name
      email
    }
    projectManager {
      name
      email
    }
    lifecycleStatus
    projectStatus
    opportunityProjectStatus
    isUnit4
  }
`;
const PROJECT_FIELDS = gql`
  fragment ProjectFields on Project {
    id
    identifier
    valueframeId
    name
    cleanName
    clientName
    buildingLocationAddress
    mapLocationExists
    mapLocationAddress
    department
    owner {
      name
      email
    }
    status
    unit4Status
    teamsToolExternalLinkAvailable
    serviceDirectoriesLocation
    plannerToolExternalLinkAvailable
    valueframeToolExternalLinkAvailable
    unit4UpdatedAt
    networkDriveToolExternalLinkAvailable
    endUserDirectoryPath
    serviceDirectoriesLocation
    offer {
      valueframeId
    }
    offers {
      valueframeId
      name
    }
    legacyNetworkDirectoryPath
    askiToolExternalLinkAvailable
    buildings {
      id
    }
  }
`;
const OFFER_FIELDS = gql`
  fragment OfferFields on Offer {
    id
    identifier
    valueframeId
    name
    cleanName
    clientName
    department
    owner {
      name
      email
    }
    deadlineAt
    status
    unit4Status
    teamsToolExternalLinkAvailable
    workspaceType
    teamsToolSharepointLinkAvailable
    plannerToolExternalLinkAvailable
    valueframeToolExternalLinkAvailable
    unit4UpdatedAt
    projects {
      valueframeId
      name
    }
    networkDriveToolExternalLinkAvailable
    endUserDirectoryPath
    legacyNetworkDirectoryPath
    buildings {
      id
    }
  }
`;

export const GET_WORKSITE = gql`
  ${BUILDING_FIELDS}
  query getBuildingWorksite($buildingWorksiteId: ID) {
    buildingWorksite(id: $buildingWorksiteId) {
      id
      name
      totalArea
      totalFloorArea
      totalLivingArea
      totalRoomArea
      policy {
        create
        destroy
        show
        update
        updateMemberships
        updateOwnerMemberships
      }
      owners {
        id
        name
        email
        o365Id
      }
      members {
        id
        name
        email
        o365Id
      }
      creator {
        id
        name
        email
        o365Id
      }
      inheritedMembers {
        id
        name
        o365Id
        email
      }
      buildings {
        ...BuildingFields
      }
      projectReferences {
        id
        projectId
      }
    }
  }
`;
export function useWorksite(worksiteId) {
  const { t } = useTranslation();
  const {
    data: { buildingWorksite } = {},
    loading,
    error,
  } = useQuery(GET_WORKSITE, {
    variables: {
      buildingWorksiteId: worksiteId,
    },
    skip: !worksiteId || worksiteId === "new",
    fetchPolicy: "cache-and-network",
  });

  const worksite = buildingWorksite
    ? getTransformedData(buildingWorksite)
    : null;

  return {
    worksite,
    policy: worksite?.policy,
    loading,
    error: error
      ? error.message.indexOf("Couldn't find BuildingWorksite") > -1
        ? t("Worksite.notFound")
        : t("Errors.search")
      : error,
  };
}
function getTransformedData(data) {
  const newData = { ...data };
  newData.inheritedMembers = getInheritedMembers(data, newData.owners);
  return newData;
}
function getInheritedMembers(data, owners) {
  const creatorId = data.creator?.id;
  const ownersIds = owners.map((i) => i.id).concat([creatorId]);
  const membersIds = data.members?.map((i) => i.id);
  const filtered = data.inheritedMembers.filter((member) => {
    if (
      !member.name ||
      ownersIds.includes(member.id) ||
      membersIds.includes(member.id)
    ) {
      return false;
    } else {
      return member;
    }
  });
  return uniq(filtered);
}

/**
 * Fetch worksite workspaces
 * */
function getWorkspaces(worksite) {
  if (!worksite) return null;
  return Object.entries(
    groupBy(worksite.workspaces, (workspace) => workspace.id)
  ).map(([id, workspaces]) => {
    return [id, workspaces[0], workspaces.length];
  });
}

const GET_WORKSITE_WORKSPACES = gql`
  ${WORKSPACE_FIELDS}
  query BuildingWorksiteWorkspaces($buildingWorksiteId: ID) {
    buildingWorksite(id: $buildingWorksiteId) {
      id
      workspaces {
        ...WorkspaceFields
      }
    }
  }
`;
export function useWorksiteWorkspaces(worksiteId) {
  const { t } = useTranslation();
  const {
    data: { buildingWorksite } = {},
    loading,
    error,
  } = useQuery(GET_WORKSITE_WORKSPACES, {
    variables: {
      buildingWorksiteId: worksiteId,
    },
    fetchPolicy: "cache-and-network",
  });

  const workspaces = getWorkspaces(buildingWorksite);

  return {
    workspaces,
    loading,
    error: error && t("Errors.search"),
  };
}

/**
 * Fetch worksite projects
 * */
function getProjects(worksite) {
  if (!worksite) return null;
  return Object.entries(
    groupBy(worksite.projects, (project) => project.id)
  ).map(([id, projects]) => {
    return [id, projects[0], projects.length];
  });
}

const GET_WORKSITE_PROJECTS = gql`
  ${PROJECT_FIELDS}
  query BuildingWorksiteProjects($buildingWorksiteId: ID) {
    buildingWorksite(id: $buildingWorksiteId) {
      id
      projects {
        ...ProjectFields
      }
    }
  }
`;
export function useWorksiteProjects(worksiteId) {
  const { t } = useTranslation();
  const {
    data: { buildingWorksite } = {},
    loading,
    error,
  } = useQuery(GET_WORKSITE_PROJECTS, {
    variables: {
      buildingWorksiteId: worksiteId,
    },
    fetchPolicy: "cache-and-network",
  });

  const projects = getProjects(buildingWorksite);

  return {
    projects,
    loading,
    error: error && t("Errors.search"),
  };
}

/**
 * Fetch worksite offers
 * */
function getOffers(worksite) {
  if (!worksite) return null;
  return Object.entries(groupBy(worksite.offers, (offer) => offer.id)).map(
    ([id, offers]) => [id, offers[0], offers.length]
  );
}

const GET_WORKSITE_OFFERS = gql`
  ${OFFER_FIELDS}
  query BuildingWorksiteOffers($buildingWorksiteId: ID) {
    buildingWorksite(id: $buildingWorksiteId) {
      id
      offers {
        ...OfferFields
      }
    }
  }
`;
export function useWorksiteOffers(worksiteId) {
  const { t } = useTranslation();
  const {
    data: { buildingWorksite } = {},
    loading,
    error,
  } = useQuery(GET_WORKSITE_OFFERS, {
    variables: {
      buildingWorksiteId: worksiteId,
    },
    fetchPolicy: "cache-and-network",
  });
  const offers = getOffers(buildingWorksite);

  return {
    offers,
    loading,
    error: error && t("Errors.search"),
  };
}

export const CREATE_WORKSITE = gql`
  mutation CreateBuildingWorksite(
    $buildingWorksite: BuildingWorksiteInputObject!
  ) {
    createBuildingWorksite(buildingWorksite: $buildingWorksite) {
      id
      name
    }
  }
`;
export const UPDATE_WORKSITE = gql`
  mutation UpdateBuildingWorksite(
    $buildingWorksite: BuildingWorksiteInputObject!
    $updateBuildingWorksiteId: ID!
  ) {
    updateBuildingWorksite(
      buildingWorksite: $buildingWorksite
      id: $updateBuildingWorksiteId
    ) {
      id
      name
    }
  }
`;
export const DELETE_WORKSITE = gql`
  mutation DestroyBuildingWorksite($destroyBuildingWorksiteId: ID!) {
    destroyBuildingWorksite(id: $destroyBuildingWorksiteId)
  }
`;
