import * as React from "react";

import { useTranslation } from "react-i18next";

import UserInfo from "./UserInfo";

import { ReactComponent as AccountIcon } from "../images/account-circle-outline.svg";

const NaviUserInfo = ({ logOut, userInfo }) => {
  const { t } = useTranslation();

  return (
    <div className="flex alignStart justify marginTop padding-0-5 padding-vertical">
      <AccountIcon />
      <div className="flexY alignEnd">
        <UserInfo
          displayName={userInfo && userInfo.displayName}
          o365Id={userInfo && userInfo.id}
          mail={userInfo && userInfo.userPrincipalName}
        />
        <button type="button" onClick={logOut} className="link nowrap small">
          {t("Navigation.logOut")}
        </button>
      </div>
    </div>
  );
};

export default NaviUserInfo;
