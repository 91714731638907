import { FeatureFlagEnum } from "./graphql/codegen/graphql";

export type TFeatureFlag = {
  name: FeatureFlagEnum;
  enabled: boolean;
};

const AppContext: {
  featureFlags: Record<string, TFeatureFlag>;
  setFeatureFlags: (featureFlags: Record<string, TFeatureFlag>) => void;
} = {
  featureFlags: {},
  setFeatureFlags: (featureFlags: Record<string, TFeatureFlag>) => {
    AppContext.featureFlags = featureFlags;
  },
};

export default AppContext;
