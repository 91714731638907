import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import denormalize from "denormalize-jsonapi";
import actionReporter from "~/config/initializers/actionReporter";
import omit from "lodash.omit";

import { useOffer } from "./offerHooks";
import { getOmittedProject } from "../components/ServiceTypesHelper";
import { buildFiltersQuery } from "../helpers";
import { useRestApiClient } from "~/lib/ClientProxy";

/**
 * Project
 * */
export function useProject(projectIdentifier, params) {
  const { t } = useTranslation();
  const api = useRestApiClient();

  return useQuery(
    ["project", { ...params, projectIdentifier }],
    () => fetchProject(api, projectIdentifier, params, t),
    {
      enabled: !!projectIdentifier,
      refetchOnWindowFocus: false,
    }
  );
}

function fetchProject(api, projectIdentifier, params, t) {
  const query = params ? buildFiltersQuery(params) : "";
  return api
    .getProject(projectIdentifier, query)
    .then(({ data }) => {
      const denormalized = denormalize(data, [
        "owner",
        "secondaryOwner",
        "creator",
        "frameAgreement",
        "offer",
        "offers",
        "owners",
        "members",
        "guests",
        "externalUsers",
        "serviceAreas",
        "serviceTypes",
        "serviceProducts",
        "workorders",
        "buildings",
        "worksites",
        "subFrameAgreement",
        "referenceGroups",
      ]);
      const transformed = transformProjectData(denormalized.data);
      return {
        project: transformed,
        policies: transformed.meta?.policies,
        error: null,
      };
    })
    .catch((error) => {
      actionReporter.notify(error);
      return {
        project: null,
        error:
          error.status && error.status === 404
            ? t("Errors.projectNotFound")
            : t("Errors.search"),
      };
    });
}

function transformProjectData(project) {
  const omitted = omit(project, [
    "offer",
    "offers",
    "frameAgreement",
    "buildings",
    "buildingSuggestions",
    "worksites",
  ]);
  omitted.offer = omitOffer(project.offer);
  omitted.offers = project.offers?.map((offer) => omitOffer(offer)) || [];
  omitted.frameAgreement = project.frameAgreement
    ? omit(project.frameAgreement, ["offers", "projects"])
    : null;
  omitted.workorders = omitSubProjects(project);
  omitted.buildings = omitBuildings(project.buildings);
  omitted.worksites = omitWorksites(project.worksites);
  omitted.frameAgreement = omitFrameAgreement(project.frameAgreement);
  omitted.subFrameAgreement = omitSubFrameAgreement(project.subFrameAgreement);
  omitted.owners = project.owners.filter((i) => i?.name || i?.email);
  omitted.members = project.members.filter((i) => i?.name || i?.email);
  return omitServices(omitted);
}

/**
 * Update Project address:
 * fetch latest from projektikartta and update info in voima
 * and return here project with latest address.
 * */
export function useProjectAddress(projectIdentifier) {
  const { t } = useTranslation();
  const api = useRestApiClient();

  return useQuery(
    ["update-project-address", projectIdentifier],
    () => updateProjectAddress(api, projectIdentifier, t),
    {
      enabled: !!projectIdentifier,
      refetchOnWindowFocus: false,
    }
  );
}

function updateProjectAddress(api, projectIdentifier, t) {
  return api
    .updateProjectAddress(projectIdentifier)
    .then((data) => {
      const addressId = data?.data?.relationships?.address?.data?.id;
      const foundAddress = addressId
        ? data?.included?.find(
            (i) => i?.type === "address" && i?.id === addressId
          )
        : null;
      const denormalized = denormalize(data, [
        "address",
        "buildingSuggestions",
      ]);
      return {
        address: foundAddress,
        buildingSuggestions: denormalized.data?.buildingSuggestions,
        error: null,
      };
    })
    .catch((error) => {
      actionReporter.notify(error);
      return {
        error: t("Errors.failedToLoadProjectAddress"),
      };
    });
}

/**
 * helper functions:
 * */
export function omitOffer(offer) {
  return offer
    ? omit(offer, [
        "projects",
        "frameAgreement",
        "serviceAreas",
        "serviceTypes",
        "serviceProducts",
        "buildings",
      ])
    : null;
}

export function omitSubProjects(project) {
  return project.workorders?.length
    ? project.workorders.map(
        ({
          id,
          valueframeId,
          owner,
          name,
          description,
          status,
          cleanName,
          identifier,
        }) => ({
          id,
          valueframeId,
          owner,
          name,
          description,
          status,
          cleanName,
          identifier,
        })
      )
    : [];
}

export function omitServices(omitted) {
  const omittedServices = getOmittedProject(omitted);
  omittedServices.serviceAreas =
    omitted.serviceAreas?.map((i) => omit(i, ["serviceTypes"])) || [];
  return omittedServices;
}

export function omitBuildings(buildings, toOmit) {
  return (
    buildings?.map((building) => {
      const newBuilding = omit(building, [
        "addresses",
        "offers",
        "projectReferences",
        "projects",
        "worksite",
      ]);
      newBuilding.addresses =
        building.addresses?.map((address) => {
          if (typeof address === "string") {
            return address;
          }
          return omit(address, ["buildings"]);
        }) || [];
      if (toOmit !== "worksite") {
        newBuilding.worksite = omit(building.worksite, [
          "buildings",
          "owners",
          "members",
        ]);
        newBuilding.worksite.buildings = omitBuildings(
          building.worksite.buildings,
          "worksite"
        );
      }
      return newBuilding;
    }) || []
  );
}

export function omitWorksites(worksites) {
  if (!worksites) return [];
  const worksitesObj = Object.fromEntries(worksites.map((i) => [i.id, i]));
  return Object.entries(worksitesObj).map(([id, worksite]) => {
    const newWorksite = omit(worksite, ["buildings", "owners", "members"]);
    newWorksite.buildingsIds = worksite.buildings?.map((i) => i.id);
    return newWorksite;
  });
}

export function omitFrameAgreement(frameAgreement) {
  const omitted = omit(frameAgreement, ["projects", "offers", "subAgreements"]);
  return omitted;
}

export function omitSubFrameAgreement(subFrameAgreement) {
  if (!subFrameAgreement) return [];
  const omitted = omit(subFrameAgreement, ["frameAgreement"]);
  omitted.frameAgreement = {
    id: subFrameAgreement.frameAgreement.id,
    name: subFrameAgreement.frameAgreement.name,
  };
  return omitted;
}

/**
 * Project or Offer
 * */
export function useProjectOffer(identifier, isProject) {
  /* fetching project */
  const { isLoading: isLoadingProject, data: projectData } = useProject(
    isProject ? identifier : null,
    { simplified: true, withServices: true }
  );
  const { project, error: projectError } = projectData || {};

  /* fetching offer */
  const { isLoading: isLoadingOffer, data: offerData } = useOffer(
    isProject ? null : identifier
  );
  const { offer, error: offerError } = offerData || {};

  const isLoading = isLoadingProject || isLoadingOffer;
  return {
    isLoading,
    project: project || offer,
    error: projectError || offerError,
  };
}
