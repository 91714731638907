import { ApplicationModel } from "./ApplicationModel";
import { HasTranslations, applyMixins } from "./mixins";
import {
  ContentAreaTypeEnum,
  ServiceArea,
  ServiceAreaTranslation,
} from "~/graphql";
import { DocumentationLibraryModel, ServiceTypeModel } from ".";

export class ServiceAreaModel extends ApplicationModel {
  private _data!: ServiceArea;

  id: string;
  slug: string;
  name: string;

  contentBlocksCount: number;

  serviceTypes?: ServiceTypeModel[];
  documentationLibrary?: DocumentationLibraryModel;

  createdAt: Date;
  updatedAt: Date;

  areaType = ContentAreaTypeEnum.ServiceArea;

  constructor(data: ServiceArea) {
    super();
    this._data = data;

    this.id = this._data.id;
    this.slug = this._data.slug;

    this.name = this._data.name;

    this.contentBlocksCount = this._data.contentBlocksCount;

    if (this._data.serviceTypes)
      this.serviceTypes = this._data.serviceTypes.map(
        (type) => new ServiceTypeModel(type)
      );
    if (this._data.documentationLibrary)
      this.documentationLibrary = new DocumentationLibraryModel(
        this._data.documentationLibrary
      );

    this.createdAt = new Date(this._data.createdAt);
    this.updatedAt = new Date(this._data.updatedAt);
  }
}
export interface ServiceAreaModel
  extends HasTranslations<ServiceAreaTranslation> {}
applyMixins(ServiceAreaModel, [HasTranslations]);
