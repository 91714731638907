import ActionReporter from "~/lib/ActionReporter";
import { reportingService } from "~/config/actionReporter";

let _actionReporter: ActionReporter | undefined;
const actionReporter = (() => {
  if (!_actionReporter) {
    _actionReporter = new ActionReporter(reportingService);
  }
  return _actionReporter;
})();

export default actionReporter;
