import * as React from "react";
import { useTranslation } from "react-i18next";

import SubNavLink from "../../components/Navigation/SubNavLink";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import NaviUserInfo from "../../components/NaviUserInfo";

import styles from "./WorksiteNavigation.module.css";

export default function WorksiteNavigation({
  rootPath,
  userInfo,
  showWorkspaces,
  redirectToWorkspaces,
  logOut,
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.subNavigation}>
      <SubNavLink
        exact
        label={t("Navigation.worksiteInformation")}
        url={rootPath}
        type="worksites"
      />
      {showWorkspaces && (
        <SubNavLink
          label={t("Navigation.workspaces")}
          url={`${rootPath}/workspaces`}
          type="worksites"
        />
      )}
      {!redirectToWorkspaces && (
        <SubNavLink
          label={t("Navigation.projects")}
          url={`${rootPath}/projects`}
          type="worksites"
        />
      )}
      {!redirectToWorkspaces && (
        <SubNavLink
          label={t("Navigation.offers")}
          url={`${rootPath}/offers`}
          type="worksites"
        />
      )}
      {!redirectToWorkspaces && (
        <SubNavLink
          label={t("Navigation.references")}
          url={`${rootPath}/references`}
          type="references"
        />
      )}
      <div className="marginTopAuto paddingTop">
        <LanguageSwitcher />
        <NaviUserInfo userInfo={userInfo} logOut={logOut} />
      </div>
    </div>
  );
}
