import {
  ContentAreaInterface,
  ContentBlockArea,
  ContentBlockAreaTranslation,
} from "~/graphql";
import {
  ApplicationModel,
  DocumentationLibraryModel,
  KnowledgeAreaModel,
  ServiceProductModel,
  ServiceTypeModel,
} from ".";
import { HasTranslations, applyMixins } from "./mixins";

export type ContentAreaModelInterface =
  | ContentAreaModel
  | KnowledgeAreaModel
  | ServiceTypeModel
  | ServiceProductModel;

export class ContentAreaModel extends ApplicationModel {
  private _data!: ContentAreaInterface;

  id: string;
  slug: string;
  name: string;
  areaType: string;
  documentationLibrary?: DocumentationLibraryModel;

  // createdAt: Date;
  // updatedAt: Date;

  static resolveArea(data: any): ContentAreaModelInterface | undefined {
    switch (data.__typename) {
      case "KnowledgeArea":
        return new KnowledgeAreaModel(data);
      case "ServiceType":
        return new ServiceTypeModel(data);
      case "ServiceProduct":
        return new ServiceTypeModel(data);
    }
  }

  constructor(data: ContentBlockArea) {
    super();
    this._data = data;

    this.id = this._data.id;
    this.slug = this._data.slug;
    this.name = this._data.name;
    this.areaType = this._data.areaType;

    if (this._data.documentationLibrary)
      this.documentationLibrary = new DocumentationLibraryModel(
        this._data.documentationLibrary
      );

    // this.createdAt = new Date(this._data.createdAt);
    // this.updatedAt = new Date(this._data.updatedAt);
  }
}
export interface ContentAreaModel
  extends HasTranslations<ContentBlockAreaTranslation> {}
applyMixins(ContentAreaModel, [HasTranslations]);
