import actionReporter from "~/config/initializers/actionReporter";
import { FeatureFlagEnum } from "../graphql/codegen/graphql";
import AppContext from "~/AppContext";

export const isFeatureEnabled = (flagName: FeatureFlagEnum) => {
  const featureFlags = AppContext.featureFlags;

// guard approach
// check if it has been initialized
if (featureFlags && Object.keys(featureFlags).length === 0) {
  const message = `Feature flags are empty and likely have not been initialized, yet.`;
  actionReporter.notify(message, { context: { data: {featureFlags, flagName} } });
  return false;
  }

  // check to see if the flag is in the list, otherwise explicitly null
  const flag = featureFlags[flagName] || null

  // flag is found
  if (flag === null) {
    const message = `Feature flag ${flagName} not found in ${JSON.stringify(featureFlags)}`;
    actionReporter.notify(message, { context: { data: {featureFlags, flagName} } });
    return false; // Default to disabled if the specific flag is not found
  }

  // check to see if enabled exists

  if (flag.enabled === null) {
    const message = `Feature flag ${flagName} has no enabled value`;
    actionReporter.notify(message, { context: { data: {featureFlags, flagName} } });
    return false;
  }

  // otherwise return the value found
  return flag.enabled
};
