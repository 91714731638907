export class GraphQLError extends Error {
  operationName: string;
  variables: Record<string, any>;
  path?: string[];
  extensions?: Record<string, any>;

  constructor(message: string, operationName: string, variables: Record<string, any>, path?: string[], extensions?: Record<string, any>) {
    super(message);
    this.name = 'GraphQLError';
    this.operationName = operationName;
    this.variables = variables;
    this.path = path;
    this.extensions = extensions;
  }
}

export function isGraphQLError(error: unknown): error is GraphQLError {
  return error instanceof GraphQLError;
}
