import * as React from "react";

import { TFeatureFlag } from "~/AppContext";
import { CurrentUser } from "~/graphql";

export interface ISecurityContext {
  ready: boolean;
  initialTokenFetchDone: boolean;
  accessToken?: string;
  account?: any;
  canEdit: boolean;
  logOutRedirect: () => void;
  setSystemNotification: (systemNotification: any) => void;
  systemNotification?: any;
  isSystemNotificationVisible?: boolean;
  userInfo?: any;
  userGroups?: any;
  currentUser?: CurrentUser;
  documentationLibraryAdmin?: boolean;
  superadmin?: boolean;
  policies?: any;
  featureFlags: Record<string, TFeatureFlag>;
}

const SecurityContext = React.createContext<ISecurityContext>({
  ready: false,
  initialTokenFetchDone: false,
  canEdit: false,
  logOutRedirect: () => {},
  setSystemNotification: (systemNotification: any) => {},
  systemNotification: null,
  isSystemNotificationVisible: false,
  userInfo: null,
  userGroups: null,
  currentUser: undefined,
  documentationLibraryAdmin: false,
  superadmin: false,
  policies: null,
  featureFlags: {},
});

export default SecurityContext;
