import { useLocation, useRouteMatch } from "react-router-dom";

import { useRiskAssessment } from "../hooks/riskAssessmentHooks";
import { useReclamation } from "../hooks/reclamationHooks";
import { useProjectFeedback } from "../hooks/projectFeedbackHooks";
import { isFeatureEnabled } from "~/hooks/featureFlagsHooks";
import { FeatureFlagEnum } from "~/graphql/codegen/graphql";

function getTypeAndNewMatch({
  isLoading,
  projectIdentifier,
  offerIdentifier,
  useVoimaIds,
}) {
  if (isLoading) {
    return { isLoading };
  } else if (projectIdentifier) {
    const newMatch = {
      params: { projectIdentifier },
      url: useVoimaIds
        ? `/projects/by_id/${projectIdentifier}`
        : `/projects/${projectIdentifier}`,
    };
    return { newMatch, type: "project" };
  } else if (offerIdentifier) {
    const newMatch = {
      params: { offerIdentifier },
      url: useVoimaIds
        ? `/offers/by_id/${offerIdentifier}`
        : `/offers/${offerIdentifier}`,
    };
    return { newMatch, type: "offer" };
  } else {
    return {};
  }
}

function useCheckRiskAssessment(useVoimaIds) {
  let location = useLocation();
  const isProject = location.state?.isProject;
  let isRiskAssessment = useRouteMatch(
    "/risk_assessments/:riskAssessmentId/edit"
  );
  const riskAssessmentId = isRiskAssessment?.params?.riskAssessmentId;

  const { isLoading, data: raData } = useRiskAssessment(riskAssessmentId);
  const { data: riskAssessment = {} } = raData || {};
  const projectIdentifier =
    isProject &&
    (useVoimaIds
      ? riskAssessment.project?.id
      : riskAssessment.project?.valueframeId)
      ? useVoimaIds
        ? riskAssessment.project?.id
        : riskAssessment.project?.valueframeId
      : null;
  const offerIdentifier =
    isProject &&
    (useVoimaIds
      ? riskAssessment.project?.id
      : riskAssessment.project?.valueframeId)
      ? null
      : useVoimaIds
        ? riskAssessment.offer?.id
        : riskAssessment.offer?.valueframeId;

  return getTypeAndNewMatch({
    isLoading,
    projectIdentifier,
    offerIdentifier,
    useVoimaIds,
  });
}

function useCheckReclamation(useVoimaIds) {
  let isReclamationId = useRouteMatch("/reclamations/:reclamationId/edit");
  const reclamationId = isReclamationId?.params?.reclamationId;

  const { isLoading, data = {} } = useReclamation(reclamationId);
  const { reclamation } = data;
  const projectIdentifier = useVoimaIds
    ? reclamation?.project?.id
    : reclamation?.project?.valueframeId;

  return getTypeAndNewMatch({ isLoading, projectIdentifier, useVoimaIds });
}

function useCheckFeedback(useVoimaIds) {
  let isFeedbackId = useRouteMatch("/feedbacks/:feedbackId");
  const feedbackId = isFeedbackId?.params?.feedbackId;

  const { feedback, loading: isLoading } = useProjectFeedback(feedbackId);
  const projectIdentifier = useVoimaIds
    ? feedback?.project?.id
    : feedback?.project?.valueframeId;

  return getTypeAndNewMatch({ isLoading, projectIdentifier, useVoimaIds });
}

function hasType(type) {
  return ["project", "offer"].includes(type);
}

export function useCheckRoute() {
  const useVoimaIds = isFeatureEnabled(FeatureFlagEnum.VoimaInternalIdPaths);

  const {
    isLoading: isLoadingRA,
    type: typeRA,
    newMatch: newMatchRA,
  } = useCheckRiskAssessment(useVoimaIds);
  const {
    isLoading: isLoadingReclamation,
    type: typeReclamation,
    newMatch: newMatchReclamation,
  } = useCheckReclamation(useVoimaIds);
  const {
    isLoading: isLoadingFeedback,
    type: typeFeedback,
    newMatch: newMatchFeedback,
  } = useCheckFeedback(useVoimaIds);

  if (isLoadingRA || isLoadingReclamation || isLoadingFeedback) {
    return { isLoading: true };
  } else if (!isLoadingRA && hasType(typeRA)) {
    return {
      type: typeRA,
      newMatch: newMatchRA,
    };
  } else if (!isLoadingReclamation && hasType(typeReclamation)) {
    return {
      type: typeReclamation,
      newMatch: newMatchReclamation,
    };
  } else if (!isLoadingFeedback && hasType(typeFeedback)) {
    return {
      type: typeFeedback,
      newMatch: newMatchFeedback,
    };
  }
}
