import * as React from "react";
import styles from "./Layout.module.css";

export const SidebarLayout = ({
  children,
  className,
  guidelines,
  frameAgreement,
  reverse,
  ...props
}: {
  children: React.ReactNode;
  className?: string;
  guidelines?: boolean;
  frameAgreement?: boolean;
  reverse?: boolean;
}) => {
  return (
    <div
      {...props}
      className={[
        className,
        styles.container,
        guidelines
          ? styles.sidebarGuidelines
          : frameAgreement
            ? styles.sidebarFrameAgreement
            : styles.sidebar,
        reverse ? styles.sidebarReverse : styles.sidebarNormal,
      ].join(" ")}
    >
      {children}
    </div>
  );
};

export const HorizontalToVerticalLayout = ({
  children,
  className,
  wider,
  ...props
}: {
  children: React.ReactNode;
  className?: string;
  wider?: boolean;
}) => {
  return (
    <div
      {...props}
      className={[
        className,
        styles.container,
        styles.horizontalToVertical,
        wider ? styles.wider : styles.narrower,
      ].join(" ")}
    >
      {children}
    </div>
  );
};

export const VerticalToHorizontalLayout = ({
  children,
  className,
  wider,
  ...props
}: {
  children: React.ReactNode;
  className?: string;
  wider?: boolean;
}) => {
  return (
    <div
      {...props}
      className={[
        className,
        styles.container,
        styles.verticalToHorizontal,
        wider ? styles.wider : styles.narrower,
      ].join(" ")}
    >
      {children}
    </div>
  );
};

export const InlineToVerticalLayout = ({
  children,
  className,
  ...props
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      {...props}
      className={[className, styles.container, styles.InlineToVertical].join(
        " "
      )}
    >
      {children}
    </div>
  );
};
