import * as MicrosoftGraphClient from "@microsoft/microsoft-graph-client";

export default class MsGraphClientProxy {
  fetchAccessToken: () => string;
  fetchLocale: () => string;

  client: MicrosoftGraphClient.Client;

  constructor({
    fetchAccessToken,
    fetchLocale,
  }: {
    fetchAccessToken: () => string;
    fetchLocale: () => string;
  }) {
    this.fetchAccessToken = fetchAccessToken;
    this.fetchLocale = fetchLocale;

    this.client = MicrosoftGraphClient.Client.init({
      authProvider: (done) => {
        done(null, this.fetchAccessToken());
      },
    });
  }

  get userInfo() {
    return this.client.api("/me").get();
  }

  get userGroups() {
    return this.client.api("/me/getMemberGroups").post({ securityEnabledOnly: false });
  }
}
