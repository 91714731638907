import * as React from "react";
import { createPortal } from "react-dom";
import cx from "classnames";

import { formatDateTime } from "~/helpers";
import styles from "./SystemNotificationToast.module.css";

const SystemNotificationToast = ({ notification, readNotification }) => {
  const { body, publishedAt, authorName } = notification;
  const publishedDate = formatDateTime(publishedAt);

  const notificationsElement = document.getElementById("notifications");
  if (!notificationsElement) {
    console.error("Element for notifications is not found");
    return null;
  }

  const toastElement = document.createElement("div");
  notificationsElement.appendChild(toastElement);

  return createPortal(
    <div className={styles.toast}>
      <div className={styles.toastBody}>{body}</div>
      <div className={cx("childMarginsHalfX", styles.toastFooter)}>
        <span className={styles.toastFooterPublished}>{publishedDate}</span>
        <span className={styles.toastFooterAuthor}>{authorName}</span>
      </div>
      <button
        className={styles.toastCloseButton}
        onClick={() => readNotification(notification)}
      ></button>
    </div>,
    toastElement
  );
};
export default SystemNotificationToast;
