// Required permissions:
// Create group: Group.ReadWrite.All
// Create team: Group.ReadWrite.All
// List apps in catalog: AppCatalog.ReadWrite.All (delegated only)
// Create planner plan: Group.ReadWrite.All (delegated only)
// Add member to group: Group.ReadWrite.All
// Group sites: Sites.ReadWrite.All

import { LogLevel } from "@azure/msal-browser";
import { o365ClientID, o365TenantID } from "~/config/env";

export { PublicClientApplication } from "@azure/msal-browser";
export { MsalProvider } from "@azure/msal-react";

export const graphScopes = [
  "openid",
  "profile",
  "User.Read",
  "User.Read.All",
  "Group.ReadWrite.All",
  "AppCatalog.ReadWrite.All",
  "Sites.ReadWrite.All",
  "Files.Read.All",
  "Directory.Read.All",
];
export const graphEndpoints = {
  me: "https://graph.microsoft.com/v1.0/me",
  messages: "https://graph.microsoft.com/v1.0/me/messages",
};

const clientId = o365ClientID;
const authority = `https://login.microsoftonline.com/${o365TenantID}`;
export const publicClientConfig = {
  auth: {
    clientId,
    authority,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage"
  },
  system: {
    allowNativeBroker: false,
    loggerOptions: {
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: true,
    },
  }
}

export const loginRequest = {
  scopes: graphScopes
};

export const logoutRequest = {};

export const tokenRequest = {
  scopes: graphScopes
}

export const silentRequest = {
  scopes: graphScopes
}
