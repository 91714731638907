import { InstanceParams } from "@datapunt/matomo-tracker-react/lib/types";
import { isLocal, isProduction } from "~/config/env";

const matomoUrlBase = "https://voima-metrics.matomo.cloud/";

export const matomoConfig: InstanceParams = {
  urlBase: matomoUrlBase,
  siteId: isProduction ? 2 : 1,
  trackerUrl: `${matomoUrlBase}matomo.php`,
  disabled: false,
  heartBeat: {
    active: true,
    seconds: 10,
  },
  linkTracking: false,
  configurations: {
    disableCookies: true,
    setSecureCookie: !isLocal,
    setRequestMethod: "POST",
  },
}
