import {
  ApplicationModel,
  DocumentationLibraryModel,
  ServiceTypeModel,
} from ".";
import { HasTranslations, applyMixins } from "./mixins";
import {
  ContentAreaTypeEnum,
  ServiceProduct,
  ServiceProductTranslation,
} from "~/graphql";

export class ServiceProductModel extends ApplicationModel {
  private _data!: ServiceProduct;

  id: string;
  slug: string;

  name: string;

  contentBlocksCount: number;

  serviceAreaId: number;
  serviceTypeId: number;
  serviceType?: ServiceTypeModel;

  documentationLibrary?: DocumentationLibraryModel;

  createdAt: Date;
  updatedAt: Date;

  areaType = ContentAreaTypeEnum.ServiceProduct;

  constructor(data: ServiceProduct) {
    super();
    this._data = data;

    this.id = this._data.id;
    this.slug = this._data.slug;

    this.name = this._data.name;

    this.contentBlocksCount = this._data.contentBlocksCount;

    this.serviceAreaId = this._data.serviceAreaId;
    this.serviceTypeId = this._data.serviceTypeId;
    if (this._data.serviceType)
      this.serviceType = new ServiceTypeModel(this._data.serviceType);

    if (this._data.documentationLibrary)
      this.documentationLibrary = new DocumentationLibraryModel(
        this._data.documentationLibrary
      );

    this.createdAt = new Date(this._data.createdAt);
    this.updatedAt = new Date(this._data.updatedAt);
  }
}
export interface ServiceProductModel
  extends HasTranslations<ServiceProductTranslation> {}
applyMixins(ServiceProductModel, [HasTranslations]);
