import * as React from "react";
import { useTranslation } from "react-i18next";

import SubNavLink from "../../components/Navigation/SubNavLink";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import NaviUserInfo from "../../components/NaviUserInfo";
import { isFeatureEnabled } from "~/hooks/featureFlagsHooks";
import { FeatureFlagEnum } from "~/graphql/codegen/graphql";

import styles from "./AgreementNavigation.module.css";

export default function AgreementNavigation({
  rootPath,
  userInfo,
  logOut,
  location,
  redirectToWorkspaces,
}) {
  const { t } = useTranslation();

  const showWorkspaces = isFeatureEnabled(FeatureFlagEnum.Workspaces);

  return (
    <div className={styles.subNavigation}>
      <SubNavLink
        exact
        label={t("Navigation.agreementInformation")}
        url={`${rootPath}${location.search}`}
        type="frameAgreements"
      />
      <SubNavLink
        label={t("Navigation.sectors")}
        url={`${rootPath}/sectors${location.search}`}
        type="frameAgreements"
      />
      <SubNavLink
        label={t("Navigation.documents")}
        url={`${rootPath}/documents${location.search}`}
        type="frameAgreements"
      />
      {!redirectToWorkspaces && (
        <SubNavLink
          label={t("Navigation.projects")}
          url={`${rootPath}/projects${location.search}`}
          type="frameAgreements"
        />
      )}
      {!redirectToWorkspaces && (
        <SubNavLink
          label={t("Navigation.offers")}
          url={`${rootPath}/offers${location.search}`}
          type="frameAgreements"
        />
      )}

      {showWorkspaces && (
        <SubNavLink
          label={t("Navigation.workspaces")}
          url={`${rootPath}/workspaces${location.search}`}
          type="frameAgreements"
        />
      )}
      <div className="marginTopAuto paddingTop">
        <LanguageSwitcher />
        <NaviUserInfo userInfo={userInfo} logOut={logOut} />
      </div>
    </div>
  );
}
