import React, { useState, useMemo } from "react";
import { Switch, Route, NavLink } from "react-router-dom";
import cx from "classnames";

import {
  SubWorksiteNavigation,
  SubWorkspaceNavigation,
  SubProjectNavigation,
  SubOfferNavigation,
  AgreementSubNavigation,
  ReferenceGroupSubNavigation,
} from "./SubNavigations";
import RootNavigation from "./RootNavigation";
import { VerticalToHorizontalLayout } from "../components/Layout";
import { useWindowSize, mobileWindowWidth } from "../helpers";
import { useCheckRoute } from "./checkRouteHelper";
import { isFeatureEnabled } from "../hooks/featureFlagsHooks";
import { FeatureFlagEnum } from "~/graphql";

import logoUrl from "../images/logo-white.svg";
import { ReactComponent as MenuIcon } from "../images/menu-24px.svg";
import { ReactComponent as CloseIcon } from "../images/close.svg";
import styles from "./MainNavigation.module.css";

function Navi({
  policies = {},
  superadmin,
  documentationLibraryAdmin,
  userInfo,
  logOut,
  showWorkspaces,
  useVoimaIds,
  redirectToWorkspaces,
}) {
  const { isLoading, type, newMatch } = useCheckRoute() || {};

  if (isLoading) return null;

  if (type === "project") {
    return (
      <SubProjectNavigation
        match={newMatch}
        userInfo={userInfo}
        logOut={logOut}
      />
    );
  } else if (type === "offer") {
    return (
      <SubOfferNavigation
        match={newMatch}
        userInfo={userInfo}
        logOut={logOut}
      />
    );
  }

  return (
    <Switch>
      <Route
        path={[
          "/workspaces",
          "/projects",
          "/offers",
          "/worksites",
          "/worksites/new",
          "/frame_agreements",
          "/frame_agreements/new",
          "/reference_groups",
          "/reference_groups/new",
        ]}
        exact
        render={() => (
          <RootNavigation
            policies={policies}
            superadmin={superadmin}
            documentationLibraryAdmin={documentationLibraryAdmin}
            showWorkspaces={showWorkspaces}
            userInfo={userInfo}
            logOut={logOut}
            redirectToWorkspaces={redirectToWorkspaces}
          />
        )}
      />
      {policies.worksite?.index && (
        <Route
          path={["/worksites/:worksiteId"]}
          render={(props) => (
            <SubWorksiteNavigation
              userInfo={userInfo}
              logOut={logOut}
              showWorkspaces={showWorkspaces}
              redirectToWorkspaces={redirectToWorkspaces}
              {...props}
            />
          )}
        />
      )}
      {showWorkspaces && (
        <Route
          path={["/workspaces/by_identifier/:workspaceId"]}
          render={() => (
            <RootNavigation
              policies={policies}
              superadmin={superadmin}
              documentationLibraryAdmin={documentationLibraryAdmin}
              showWorkspaces={showWorkspaces}
              userInfo={userInfo}
              logOut={logOut}
              redirectToWorkspaces={redirectToWorkspaces}
            />
          )}
        />
      )}
      {showWorkspaces && (
        <Route
          path={["/workspaces/:workspaceId"]}
          render={(props) => (
            <SubWorkspaceNavigation
              userInfo={userInfo}
              logOut={logOut}
              {...props}
            />
          )}
        />
      )}
      {useVoimaIds && (
        <Route
          path={["/projects/by_id/:projectIdentifier"]}
          render={(props) => (
            <SubProjectNavigation
              userInfo={userInfo}
              logOut={logOut}
              {...props}
            />
          )}
        />
      )}
      {useVoimaIds && (
        <Route
          path={["/offers/by_id/:offerIdentifier"]}
          render={(props) => (
            <SubOfferNavigation
              userInfo={userInfo}
              logOut={logOut}
              {...props}
            />
          )}
        />
      )}
      {!useVoimaIds && (
        <Route
          path={["/projects/:projectIdentifier"]}
          render={(props) => (
            <SubProjectNavigation
              userInfo={userInfo}
              logOut={logOut}
              {...props}
            />
          )}
        />
      )}
      {!useVoimaIds && (
        <Route
          path={["/offers/:offerIdentifier"]}
          render={(props) => (
            <SubOfferNavigation
              userInfo={userInfo}
              logOut={logOut}
              {...props}
            />
          )}
        />
      )}
      <Route
        path={["/frame_agreements/:agreementId"]}
        render={(props) => (
          <AgreementSubNavigation
            userInfo={userInfo}
            logOut={logOut}
            redirectToWorkspaces={redirectToWorkspaces}
            {...props}
          />
        )}
      />
      <Route
        path={["/reference_groups/:referenceGroupId"]}
        render={(props) => (
          <ReferenceGroupSubNavigation
            userInfo={userInfo}
            logOut={logOut}
            {...props}
          />
        )}
      />
      <Route
        render={() => (
          <RootNavigation
            policies={policies}
            superadmin={superadmin}
            documentationLibraryAdmin={documentationLibraryAdmin}
            userInfo={userInfo}
            logOut={logOut}
            redirectToWorkspaces={redirectToWorkspaces}
            showWorkspaces={showWorkspaces}
          />
        )}
      />
    </Switch>
  );
}

/**
 * Main
 * */
function MainNavigation({
  logOut,
  userInfo,
  policies,
  superadmin,
  documentationLibraryAdmin,
  isSystemNotificationVisible,
}) {
  const size = useWindowSize();
  const isMobile = useMemo(() => mobileWindowWidth(size.width), [size.width]);
  const [menuOpen, setMenuOpen] = useState(false);

  const showWorkspaces = isFeatureEnabled(FeatureFlagEnum.Workspaces);
  const useVoimaIds = isFeatureEnabled(FeatureFlagEnum.VoimaInternalIdPaths);
  const redirectToWorkspaces =
    showWorkspaces && isFeatureEnabled(FeatureFlagEnum.RedirectToWorkspaces);

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  return (
    <VerticalToHorizontalLayout
      className={isSystemNotificationVisible ? styles.marginTop : ""}
    >
      {isMobile && (
        <div className={styles.mobileRibbon}>
          <NavLink to={redirectToWorkspaces ? "/workspaces" : "/projects"}>
            <div className={cx(styles.logoContainer)}>
              <img src={logoUrl} alt="Sitowise Voima" />
            </div>
          </NavLink>
          <button
            className={styles.mobileMenuIcon}
            onClick={() => toggleMenu()}
          >
            {menuOpen ? <CloseIcon className="icon" /> : <MenuIcon />}
          </button>
        </div>
      )}

      <nav
        className={cx(
          `flexY wrap alignCenter childMarginsX ${
            isMobile ? styles.mobileView : ""
          } ${menuOpen ? styles.menuIsOpen : ""}`,
          styles.navigation
        )}
      >
        <div className={cx(styles.container)}>
          <h1 className={cx(styles.logoContainer, "flex alignCenter")}>
            <NavLink to={redirectToWorkspaces ? "/workspaces" : "/projects"}>
              <img src={logoUrl} alt="Sitowise Voima" className={styles.logo} />
            </NavLink>
          </h1>

          <Navi
            policies={policies}
            superadmin={superadmin}
            documentationLibraryAdmin={documentationLibraryAdmin}
            userInfo={userInfo}
            logOut={logOut}
            showWorkspaces={showWorkspaces}
            useVoimaIds={useVoimaIds}
            redirectToWorkspaces={redirectToWorkspaces}
          />
        </div>
      </nav>
    </VerticalToHorizontalLayout>
  );
}

export default MainNavigation;
