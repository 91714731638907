import sortBy from "lodash.sortby";
import compact from "lodash.compact";
import omit from "lodash.omit";
import * as Yup from "yup";

export function getCleanServiceProducts(type) {
  return type.serviceProducts?.map((product) => {
    if (typeof product === "object") {
      const newProduct = { ...product };
      if (typeof product.serviceType === "object") {
        newProduct.serviceType = omit(product.serviceType, [
          "serviceArea",
          "serviceProducts",
        ]);
        newProduct.serviceType.serviceArea = omit(
          product.serviceType.serviceArea,
          ["serviceTypes"]
        );
      }
      return newProduct;
    } else {
      return product;
    }
  });
}
export function getCleanServiceTypes(area) {
  return area.serviceTypes?.map((type) => {
    if (typeof type === "object") {
      const newType = { ...type };
      if (typeof type.serviceArea === "object") {
        newType.serviceArea = omit(type.serviceArea, ["serviceTypes"]);
      }
      const products = getCleanServiceProducts(type);
      newType.serviceProducts = sortBy(products, (product) => product.position);
      return newType;
    } else {
      return type;
    }
  });
}
export function cleanAndOrderData(denormalized) {
  const omitted = denormalized.map((area) => {
    const newArea = { ...area };
    const types = getCleanServiceTypes(area);
    newArea.serviceTypes = sortBy(types, (i) => i.position);
    return newArea;
  });
  return sortBy(omitted, (i) => i.name);
}

function getAreaTypeData(serviceAreas, type) {
  let typeObj = null,
    areaObj = null;
  serviceAreas.some((area) => {
    if (area.name && area.serviceTypes?.length) {
      const found = area.serviceTypes.find((i) => i.id === type.id);
      if (found) {
        typeObj = found;
        areaObj = area;
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  return { typeObj, areaObj };
}

function getAreaTypeProductData(serviceAreas, product) {
  let productName = null,
    typeObj = null,
    areaObj = null;
  serviceAreas.some((area) => {
    if (area.name && area.serviceTypes?.length) {
      return area.serviceTypes.some((type) => {
        if (type.name && type.serviceProducts?.length) {
          const found = type.serviceProducts.find((i) => i.id === product.id);
          if (found) {
            productName = found.name;
            typeObj = type;
            areaObj = area;
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
    } else {
      return false;
    }
  });
  return { productName, typeObj, areaObj };
}

export function getType(type, serviceAreas) {
  if (type.name && type.serviceArea?.name) {
    return {
      type: type,
      area: type.serviceArea,
    };
  } else if (type.id) {
    const { typeObj, areaObj } = getAreaTypeData(serviceAreas, type);
    if (typeObj && areaObj) {
      return {
        type: typeObj,
        area: areaObj,
      };
    }
  }
}

export function getProduct(product, serviceAreas) {
  if (product.serviceType?.name && product.serviceArea?.name) {
    return {
      product: product.name,
      type: product.serviceType,
      area: product.serviceArea,
    };
  } else if (product.id) {
    const { productName, typeObj, areaObj } = getAreaTypeProductData(
      serviceAreas,
      product
    );
    if (productName && typeObj && areaObj) {
      return {
        product: productName,
        type: typeObj,
        area: areaObj,
      };
    }
  }
}

export function getCurrentValue(project) {
  return {
    serviceTypes: project.serviceTypes,
    serviceTypeIds: project.serviceTypes?.map((i) => i.id),
    serviceProducts: project.serviceProducts,
    serviceProductIds: project.serviceProducts?.map((i) => i.id),
  };
}

export function getServicesValues(project, offerValues) {
  if (project.initialized || !offerValues) {
    return getCurrentValue(project);
  } else {
    return getCurrentValue(offerValues);
  }
}

export function getOmittedProject(denormalized) {
  return {
    ...denormalized,
    serviceTypes: getCleanServiceTypes({
      serviceTypes: denormalized.serviceTypes,
    }),
    serviceProducts: getCleanServiceProducts({
      serviceProducts: denormalized.serviceProducts,
    }),
  };
}

export const ServiceTypesSchema = Yup.object().shape({
  serviceTypes: Yup.array().compact(),
  serviceTypeIds: Yup.array().compact(),
  serviceProducts: Yup.array().compact(),
  serviceProductIds: Yup.array().compact(),
});

export function getServiceTypeIds(project) {
  const ids = compact(
    project?.serviceTypes.map((type) => type.id && parseInt(type.id)) || []
  );
  return ids.length ? ids : null;
}
