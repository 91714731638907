import { isDevelopment } from "~/config/env";

export const defaultLanguage = "fi";

const devLngs = {
  sv: { nativeName: "Svenska" },
};
export const lngs = {
  fi: { nativeName: "Suomi" },
  en: { nativeName: "English" },
  ...(isDevelopment ? devLngs : {}),
};

export function detectLanguage() {
  const locale = navigator?.language;
  let language: string | undefined;
  if (locale?.indexOf("-") > -1) {
    [language] = locale.split("-");
  }
  return language || locale;
}
