import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import SubNavLink from "../components/Navigation/SubNavLink";
import SecurityContext from "../components/SecurityContext";

import LanguageSwitcher from "../components/LanguageSwitcher";
import NaviUserInfo from "../components/NaviUserInfo";

import styles from "./OfferNavigation.module.css";

export default function OfferNavigation({
  offer,
  rootPath,
  policies,
  showDocsTab,
  userInfo,
  logOut,
}) {
  const { t } = useTranslation();
  const { superadmin } = useContext(SecurityContext);

  return (
    <>
      <div className={cx(styles.subNavigation)}>
        <SubNavLink
          exact
          label={t("Navigation.basicInformation")}
          url={rootPath}
          type="offers"
        />
        {offer.offerChecklistGenerated && (
          <SubNavLink
            label={t("Navigation.checklist")}
            url={`${rootPath}/checklist`}
            type="offers"
          />
        )}
        <SubNavLink
          label={t("Navigation.workspaceTools")}
          url={`${rootPath}/workspace_tools`}
          disabled={!policies?.offer?.showWorkspaceTools}
          type="offers"
        />
        <SubNavLink
          label={t("Navigation.riskAssessments")}
          url={`${rootPath}/risk_assessments`}
          disabled={!policies?.riskAssessment?.index}
          type="offers"
        />
        <SubNavLink
          label={t("Navigation.responsibility")}
          url={`${rootPath}/responsibility_questionnaire`}
          disabled={!policies?.offer?.showResponsibilityForm}
          type="offers"
        />
        <SubNavLink
          label={t("Navigation.bidInformation")}
          url={`${rootPath}/bid_groups`}
          disabled={!policies?.offerBidGroup?.index}
          type="offers"
        />
        <SubNavLink
          label={t("Navigation.guidelines")}
          url={`${rootPath}/services_documentations`}
          disabled={!showDocsTab}
          type="offers"
        />
        {superadmin && (
          <SubNavLink
            label={t("Navigation.admin")}
            url={`${rootPath}/admin`}
            type="offers"
          />
        )}
        <div className="marginTopAuto paddingTop">
          <LanguageSwitcher />
          <NaviUserInfo userInfo={userInfo} logOut={logOut} />
        </div>
      </div>
    </>
  );
}
