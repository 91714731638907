import { useQuery } from "react-query";
import denormalize from "denormalize-jsonapi";
import actionReporter from "~/config/initializers/actionReporter";
import omit from "lodash.omit";

import {
  omitSubProjects,
  omitBuildings,
  omitServices,
  omitWorksites,
  omitSubFrameAgreement,
  omitFrameAgreement,
} from "./projectHooks";
import { useRestApiClient } from "~/lib/ClientProxy";

/**
 * Offer
 * */
export function useOffer(offerIdentifier) {
  const api = useRestApiClient();

  return useQuery(
    ["offer", offerIdentifier],
    () => fetchOffer(api, offerIdentifier),
    {
      enabled: !!offerIdentifier,
      refetchOnWindowFocus: false,
    }
  );
}

function fetchOffer(api, offerIdentifier) {
  return api
    .getOffer(offerIdentifier)
    .then(({ data }) => {
      const denormalized = denormalize(data, [
        "owner",
        "secondaryOwner",
        "members",
        "owners",
        "creator",
        "frameAgreement",
        "projects",
        "serviceAreas",
        "serviceTypes",
        "serviceProducts",
        "buildings",
        "worksites",
        "subFrameAgreement",
        "referenceGroups",
      ]);
      const transformed = transformOfferData(denormalized.data);
      return {
        offer: transformed,
        policies: transformed.meta?.policies,
        error: null,
      };
    })
    .catch((error) => {
      actionReporter.notify(error);
      return {
        offer: null,
        error:
          error.status && error.status === 404
            ? "Tarjousta ei löydy"
            : "Tietojen haku epäonnistui",
      };
    });
}

function transformOfferData(offer) {
  const omitted = omit(offer, [
    "projects",
    "frameAgreement",
    "buildings",
    "worksites",
    "subFrameAgreement",
  ]);
  omitted.projects = offer.projects
    ? offer.projects.map((project) => omitProject(project))
    : null;
  omitted.frameAgreement = offer.frameAgreement
    ? omit(offer.frameAgreement, ["offers", "projects"])
    : null;
  omitted.workorders = omitSubProjects(offer);
  omitted.buildings = omitBuildings(offer.buildings);
  omitted.worksites = omitWorksites(offer.worksites);
  omitted.frameAgreement = omitFrameAgreement(offer.frameAgreement);
  omitted.subFrameAgreement = omitSubFrameAgreement(offer.subFrameAgreement);
  return omitServices(omitted);
}

export function omitProject(project) {
  return project
    ? omit(project, [
        "offer",
        "offers",
        "frameAgreement",
        "serviceAreas",
        "serviceTypes",
        "serviceProducts",
        "buildings",
      ])
    : null;
}
