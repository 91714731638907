import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import denormalize from "denormalize-jsonapi";
import actionReporter from "~/config/initializers/actionReporter";

import { useRestApiClient } from "~/lib/ClientProxy";

export function useProjectFeedback(feedbackId) {
  const api = useRestApiClient();

  const { isLoading, data = {} } = useQuery(
    ["project-feedback", feedbackId],
    () => fetchFeedback(api, feedbackId),
    {
      enabled: !!feedbackId,
      refetchOnWindowFocus: false,
    }
  );

  return { feedback: data?.feedback, error: data?.error, loading: isLoading };
}

function fetchFeedback(api, feedbackId) {
  return api
    .getProjectFeedback(feedbackId)
    .then(({ data }) => {
      const result = denormalize(data, ["project", "answers"]).data;
      return {
        feedback: result,
        error: !result,
      };
    })
    .catch((error) => {
      actionReporter.notify(error);
      return {
        feedback: null,
        error: true,
      };
    });
}

export function useProjectFeedbacks(projectIdentifier) {
  const api = useRestApiClient();
  const { t } = useTranslation();

  return useQuery(
    ["project-feedbacks", projectIdentifier],
    () => fetchFeedbacks(api, projectIdentifier, t),
    {
      enabled: !!projectIdentifier,
      refetchOnWindowFocus: false,
    }
  );
}

function fetchFeedbacks(api, projectIdentifier, t) {
  return api.getProjectFeedbacks(projectIdentifier).then(
    ({ data }) => {
      const denormalized = denormalize(data, ["project", "answers"]);

      if (denormalized?.meta?.policies?.survey?.index) {
        const clientArr = denormalized.data.filter(
          (i) => i.surveyType === "client"
        );
        const employeeArr = denormalized.data.filter(
          (i) => i.surveyType === "employee"
        );
        return {
          clientSurveys: clientArr,
          employeeSurveys: employeeArr,
          error: null,
        };
      } else {
        return {
          clientSurveys: null,
          employeeSurveys: null,
          error: t("Errors.noPermissionToDownload"),
        };
      }
    },
    (error) => {
      return {
        clientSurveys: null,
        employeeSurveys: null,
        error: t("Errors.couldNotFind"),
      };
    }
  );
}
