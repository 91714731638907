import { WorkspaceRiskLevelEnum } from "./graphql";

export const riskLevels = (
  t
): {
  id: string;
  value: WorkspaceRiskLevelEnum;
  label: string;
  description: string;
}[] => {
  return [
    {
      id: "riskLevelXS",
      value: WorkspaceRiskLevelEnum.Xs,
      label: "XS",
      description: t("General.riskLevels.descriptionXS"),
    },
    {
      id: "riskLevelS",
      value: WorkspaceRiskLevelEnum.S,
      label: "S",
      description: t("General.riskLevels.descriptionS"),
    },
    {
      id: "riskLevelM",
      value: WorkspaceRiskLevelEnum.M,
      label: "M",
      description: t("General.riskLevels.descriptionM"),
    },
    {
      id: "riskLevelL",
      value: WorkspaceRiskLevelEnum.L,
      label: "L",
      description: t("General.riskLevels.descriptionL"),
    },
    {
      id: "riskLevelXL",
      value: WorkspaceRiskLevelEnum.Xl,
      label: "XL",
      description: t("General.riskLevels.descriptionXL"),
    },
  ];
};

export const riskLevelsWithNumbers = (t) => {
  return [
    {
      id: "all",
      value: undefined,
      label: t("General.riskLevels.all"),
      description: t("General.riskLevels.descriptionAll"),
    },
    ...riskLevels(t),
  ].map((level, index) => ({ ...level, number: index }));
};

export const securityLevels = (t) => {
  return [
    {
      id: "securityLevelPubliclyVisible",
      value: "publicly_visible",
      shortLabel: t("General.securityLevels.publicShortLabel"),
      label: t("General.securityLevels.publicLabel"),
      description: t("General.securityLevels.publicDescription"),
    },
    {
      id: "securityLevelPrivatelyVisible",
      value: "privately_visible",
      shortLabel: t("General.securityLevels.privateShortLabel"),
      label: t("General.securityLevels.privateLabel"),
      description: t("General.securityLevels.privateDescription"),
    },
    {
      id: "securityLevelRestricted",
      value: "restricted",
      shortLabel: t("General.securityLevels.restrictedShortLabel"),
      label: t("General.securityLevels.restrictedLabel"),
      description: t("General.securityLevels.restrictedDescription"),
    },
    {
      id: "securityLevelSecret",
      value: "secret",
      shortLabel: t("General.securityLevels.secretShortLabel"),
      label: t("General.securityLevels.secretLabel"),
      description: t("General.securityLevels.secretDescription"),
    },
  ];
};

export const businessAreas = ["Talo", "Infra", "Digi"];

export const I18N_ENABLED = true;

export const getOfferStatuses = (t) => {
  return [
    {
      label: t("Offers.statuses.leadNoWorkspace"),
      value: "Vainu (Ei tarjoustyötilaa)",
    },
    { label: t("Offers.statuses.lead"), value: "Vainu" },
    { label: t("Offers.statuses.offerSubmitted"), value: "Tarjous jätetty" },
    { label: t("Offers.statuses.won"), value: "Voitettu" },
    { label: t("Offers.statuses.lost"), value: "Hävitty" },
    { label: t("Offers.statuses.offerInProgress"), value: "Tarjous tekeillä" },
    { label: t("Offers.statuses.declined"), value: "Kieltäydytty" },
    { label: t("Offers.statuses.aborted"), value: "Keskeytetty" },
    {
      label: t("Offers.statuses.applicationToParticipate"),
      value: "Osallistumishakemus",
    },
    { label: t("Offers.statuses.offerRequest"), value: "Tarjouspyyntö" },
  ];
};
